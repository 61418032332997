import queryString from "query-string";
import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/vinculo_pessoa_empresa";

export const loadVinculos = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });
  return (dispatch) => {
    dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.get(`/api/v1/collections/list?${qry}`).then(
      (response) => {
        const vinculos = response.data?.data;
        dispatch([
          { type: types.SET_VINCULO_PESSOA_EMPRESA, payload: vinculos },
          {
            type: types.LOAD_VINCULOS_PFPJ_TOTAL,
            payload: parseInt(response.data.total),
          },

          { type: types.LOADING_VINCULOS, payload: false },
        ]);
      },
      (error) => {
        console.log(error);
        dispatch({ type: types.LOADING_VINCULOS, payload: false });
      }
    );
  };
};

export const loadFuncionarioVinculo = (funcionarioId) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.get(`/api/v1/collections/employer/${funcionarioId}`).then(
      (response) => {
        const data = response.data;
        dispatch([
          { type: types.SET_FUNCIONARIO_VINCULADO_INFO, payload: data.data },
          { type: types.LOADING_VINCULOS, payload: false },
        ]);
      },
      (error) => {
        console.log(error);
        dispatch({ type: types.LOADING_VINCULOS, payload: false });
      }
    );
  };
};

export function inserirVinculos(vinculos) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.post(`/api/v1/collections/create`, vinculos).then(
      ({ data }) => {
        store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
        reject();
      }
    );
  });
}

export function editarVinculos(id, dados) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.post(`/api/v1/collections/edit/${id}`, dados).then(
      ({ data }) => {
        store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
        reject();
      }
    );
  });
}

export function disabledFuncionario(id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.put(`/api/v1/funcionarios/disable/${id}`).then(
      ({ data }) => {
        store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
        reject();
      }
    );
  });
}



export const loadFuncionariosDisponiveisVinculo = (id, filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });
  return (dispatch) => {
    dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.get(`/api/v1/collections/available/company/${id}?${qry}`).then(
      (response) => {
        const funcionarios = response.data?.data;
        store.dispatch([
          { type: types.FUNCIONARIOS_DISPONIVEIS_LOAD, payload: funcionarios },
          {
            type: types.FUNCIONARIOS_DISPONIVEIS_TOTAL,
            payload: parseInt(response.data.total),
          },
          { type: types.LOADING_VINCULOS, payload: false },
        ]);
      },
      (error) => {
        console.log(error);
        dispatch({ type: types.LOADING_VINCULOS, payload: false });
      }
    );
  };
};
