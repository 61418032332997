import styled from "@emotion/styled";
import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataTable, InputText } from "../../../components/index";
import { loadArquivos } from "../../../store/actions/arquivos";
import {
  ConsultaPastaFuncionario,
  loadPastaFuncionario,
} from "../../../store/actions/pastas";
import { ListarTiposPorCategoria } from "../../../store/actions/tipos_de_arquivos";
import { loadFuncionarioVinculo } from "../../../store/actions/vinculo_pessoa_empresa";
import ModalArquivosDigitalizados from "./modalArquivos";
import ModalCriarPasta from "./modalCriarPasta";
import AlertComponent from "../../../components/AlertComponent";

import ModalDigitalizarArquivo from "./modalDigitalizarArquivos";

function OpenFolderEmployee(data) {
  const dispatch = useDispatch();
  const { idFuncionario } = useParams();
  const { pastaFuncionario, categoria_disponivel } = useSelector(
    (state) => state.pastas
  );
  const { arquivos } = useSelector((state) => state.arquivos);
  const { listaPorCategoria } = useSelector((state) => state.tipo_de_arquivos);
  const { funcionarioVinculado } = useSelector(
    (state) => state.vinculo_pessoa_empresa
  );

  const [data_modal_list_arquivos, setDataModalListArquivos] = useState({});
  const [listaDeArquivos, setListadeArquivos] = useState();
  const [openModalArquivos, setOpenModalArquivos] = useState(false);
  const [openModalUploadArquivo, setOpenModalUploadArquivo] = useState(false);
  const [openModalCriarPasta, setOpenModalCriarPasta] = useState(false);

  const [listaPastaFuncionario, setListaPastaFuncionario] = useState(pastaFuncionario)
  const [searchTerm, setSearchTerm] = useState("");

  // Função para lidar com a mudança no campo de busca
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const filteredList = pastaFuncionario.filter((item) =>
        item.descricao.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setListaPastaFuncionario(filteredList);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, pastaFuncionario]);



  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("");

  async function setOpenListArquivos(data) {
    try {
      dispatch([loadArquivos(data.id)]);
      setOpenModalArquivos(true);
      setListadeArquivos(data.id);
      setDataModalListArquivos(data);
    } catch {
      console.log("erro");
    }
  }

  const [permissions, setPermissions] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const _permissoes = localStorage.getItem("permissoes");
    setPermissions(_permissoes);
    dispatch([
      loadPastaFuncionario(idFuncionario),
      loadFuncionarioVinculo(idFuncionario),
    ]);
  }, []);

  const [useFolderUpload, setFolderUpload] = useState();

  function SelectFolderToUpload(data) {
    dispatch(ListarTiposPorCategoria({ categoria_id: data.categoria_id }));
    setFolderUpload(data);
    setOpenModalUploadArquivo(true);
  }

  function CreatefolderEmployee() {
    dispatch(ConsultaPastaFuncionario(funcionarioVinculado[0]?.id));
    setOpenModalCriarPasta(true);
  }

  const ButtonSelect = styled.button`
    cursor: pointer;
    width: 160px;
    margin-top: 5px;
    border: 1px solid #060D13;
    background: transparent;
    padding: 8px;
    font-size: 12px;
    border-radius: 7px;
    &:hover {
      background-color: #060D13;
      color: #ffffff;
      transition: 0.2s ease-in-out;
    }
  `;

  return (
    <div>
      {permissions?.includes(14) && (
        <div style={{ padding: "15px 5px" }}>
          <ButtonSelect
            style={{ float: "right" }}
            onClick={() => CreatefolderEmployee()}
          >
            Criar pasta
          </ButtonSelect>
        </div>
      )}
      <div style={{ margin: "25px 12px" }}>
        Abaixo as pastas criadas para o funcionário:{" "}
        <b>{pastaFuncionario[0]?.nome}</b>
      </div>

      <div style={{padding: 15, width: "50%"}}>
      <InputText
        type="text"
        placeholder="Pesquisar por descrição..."
        value={searchTerm}
        onChange={handleChange}
      />
    
    </div>


      <Divider />
      <br />
      <PerfectScrollBar>
        <DataTable
          columns={[
            {
              title: "Descrição",
              field: "descricao",
              // cellStyle: { textAlign: "center" },
            },
            {
              title: `${
                permissions?.includes(15) ? "Arquivos Digitalizados" : ""
              }`,
              cellStyle: { textAlign: "center" },
              render: (row) => {
                return (
                  <div>
                    {permissions?.includes(15) && (
                      <ButtonSelect onClick={() => setOpenListArquivos(row)}>
                        Ver Arquivos
                      </ButtonSelect>
                    )}
                  </div>
                );
              },
            },
            {
              title: `${
                permissions?.includes(12) ? "Digitalizar Arquivos" : ""
              }`,
              field: "funcionario",
              cellStyle: { textAlign: "center" },
              render: (row) => {
                return (
                  <div>
                    {permissions?.includes(12) && (
                      <ButtonSelect onClick={() => SelectFolderToUpload(row)}>
                        Enviar Arquivo
                      </ButtonSelect>
                    )}
                  </div>
                );
              },
            },          
          ]}
          data={listaPastaFuncionario}
          
          options={{
            headerStyle: {
              backgroundColor: "#F2F2F3",
              fontSize: 12,
              textAlign: "center",
              borderLeft: "solid 0.5px white",
            },
            rowStyle: (rowData) => ({
              fontSize: 14,
              textAlign: "center",
            }),
            sorting: false
          }}
          
        />
      </PerfectScrollBar>



      <ModalArquivosDigitalizados
        open={openModalArquivos}
        arquivos={arquivos}
        idLista={listaDeArquivos}
        closeModal={() => setOpenModalArquivos(false)}
        permissions={permissions}
      />
      <ModalDigitalizarArquivo
        openModal={openModalUploadArquivo}
        closeModal={() => setOpenModalUploadArquivo(false)}
        pasta={useFolderUpload}
        tipo_de_arquivos={listaPorCategoria}
        showSuccessAlert={showSuccessAlert}
        showErrorAlert={showErrorAlert}
        messageError={messageError}
        setShowSuccessAlert={setShowSuccessAlert}
        setShowErrorAlert={setShowErrorAlert}
        setMessageError={setMessageError}
      />

      <ModalCriarPasta
        open={openModalCriarPasta}
        categorias={categoria_disponivel}
        setClose={() => setOpenModalCriarPasta(false)}
        collection={funcionarioVinculado[0]?.id}
        idFuncionario={idFuncionario}
        showSuccessAlert={showSuccessAlert}
        showErrorAlert={showErrorAlert}
        messageError={messageError}
        setShowSuccessAlert={setShowSuccessAlert}
        setShowErrorAlert={setShowErrorAlert}
        setMessageError={setMessageError}
      />

      {showSuccessAlert && (
        <AlertComponent type="success" message={messageError} timeout={3000} />
      )}

      {showErrorAlert && (
        <AlertComponent type="error" message={messageError} timeout={3000} />
      )}
    </div>
  );
}

export default OpenFolderEmployee;
