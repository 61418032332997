import axios from 'axios';

const baseUrl = () => {
    // return 'https://api.somma.gedsupportempresarial.com.br'
    return 'https://somma.ged.api.kliklabs.com.br'
    // return 'http://localhost:3001'

}


const http = axios.create({ baseURL: baseUrl(), timeout: 200000});

export default http;