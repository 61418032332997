import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({

    
  root: {
    textTransform: 'uppercase', // Transforma o texto para letras maiúsculas
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));


const CustomButton = ({ variant, children, ...props }) => {
  const classes = useStyles();

  let buttonClass;
  switch (variant) {
    case 'primary':
      buttonClass = classes.primary;
      break;
    case 'secondary':
      buttonClass = classes.secondary;
      break;
    case 'disabled':
      buttonClass = classes.disabled;
      break;
    default:
      buttonClass = classes.primary;
  }

  return (
    <Button
      className={`${classes.root} ${buttonClass}`}
      disabled={variant === 'disabled'}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
