import { Paper, Tooltip, createMuiTheme } from "@material-ui/core";
import { DriveFolderUpload } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataGridTable, InputText, Loading } from "../../../components";
import CustomButton from "../../../components/ButtonComponent";
import { ActionFuncionariosSemArquivos } from "../../../store/actions/arquivos";
import { inserirPastas } from "../../../store/actions/pastas";
import ModalDigitalizarArquivo from "./modalUploadArquivo";

function FuncionariosSemArquivo() {
  const { id, modalidade } = useParams();
  const _empresa_id = localStorage.getItem("empresa_id");
  const [data, setData] = useState([]);
  const [modalUploadArquivo, setModalUploadArquivo] = useState(false);
  const [pasta, setPasta] = useState({
    collection_id: "",
    categoria_id: "",
    tipo_arquivo_id: "",
    tipo_arquivo_nome: "",
    pasta_id: "",
    categoria_descricao: ""
  });
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState("");


  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#1976d2", // Azul padrão do Material-UI
        dark: "#004ba0",
        contrastText: "#fff",
      },
      secondary: {
        main: "#dc004e", // Vermelho padrão do Material-UI
        dark: "#9a0036",
        contrastText: "#fff",
      },
      action: {
        disabledBackground: "#e0e0e0",
        disabled: "#9e9e9e",
      },
    },
  });

  async function InserirPastaArquivosFaltantes(data) {
   
    setLoading(true);
    try {
      await inserirPastas({
        categoria_id: data.categoria_id,
        collection_id: data.collection_id,
      }).then((res) => {
        setPasta({...data, pasta_id: res.pasta });
      })
      setModalUploadArquivo(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  async function LoadActionFuncionarios() {
    setLoading(true);
    try {
      await ActionFuncionariosSemArquivos({
        tipo_arquivo_id: id,
        empresa_id: _empresa_id,
        download: false,
        arquivo: modalidade
      }).then((res) => {
        setData(res.resultados);
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  async function BaixarArquivoCompleto(nomeArquivo) {
    setLoading(true);
    try {
      await ActionFuncionariosSemArquivos(
        {
          tipo_arquivo_id: id,
          empresa_id: _empresa_id,
          download: true,
          arquivo: modalidade
        },
        nomeArquivo
      );
      LoadActionFuncionarios();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  useEffect(() => {
    LoadActionFuncionarios();
  }, []);

  const handleFiltroChange = (e) => {
    setFiltro(e.target.value);
  };

  const applyFilterAndSort = (funcionarios) => {
    let filteredData = funcionarios;

    if (filtro) {
      filteredData = filteredData.filter((funcionario) =>
        funcionario.nome.toLowerCase().includes(filtro.toLowerCase())
      );
    }

    return filteredData.sort((a, b) =>
      a.nome.localeCompare(b.nome, "pt", { sensitivity: "base" })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      {data.map((item, index) => {
        return (
          <div key={item.categoria_id}>
            {item.tipos_arquivos.map((funcionarios, idx) => {
              return (
                <div key={funcionarios.id} style={{ padding: 15 }}>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h4>Categoria principal: {item.categoria_descricao}</h4>
                      <h5 style={{ fontWeight: "normal" }}>
                        Funcionários que não possuem o documento digitalizado:{" "}
                        <b>{funcionarios.tipo_arquivo.descricao}</b>
                      </h5>

                      <InputText
                        label="Buscar por nome"
                        value={filtro}
                        onChange={handleFiltroChange}
                      />
                    </div>
                    <div>
                      <CustomButton
                        onClick={() =>
                          BaixarArquivoCompleto(
                            funcionarios.tipo_arquivo.descricao
                          )
                        }
                      >
                        Baixar lista completa
                      </CustomButton>
                    </div>
                  </div>
                  <Paper elevation={3} style={{ fontSize: 12 }}>
                    <DataGridTable
                      columns={[
                        { title: "Funcionário", field: "nome" },
                        { title: "Documento", field: "cpf_cnpj" },

                        {
                          title: "Ações",
                          cellStyle: { width: "15%", textAlign: "center" },
                          render: (row) => {
                            return (
                              <>
                                <Tooltip title="Enviar o arquivo">
                                  <button
                                    style={{
                                      cursor: "pointer",
                                      background: "transparent",
                                      border: "none",
                                    }}
                                    onClick={() =>
                                      InserirPastaArquivosFaltantes({
                                        collection_id: row.collection_id,
                                        categoria_id: item.categoria_id,
                                        categoria_descricao: item.categoria_descricao,
                                        tipo_arquivo_id:
                                          funcionarios.tipo_arquivo.id,
                                        tipo_arquivo_nome:
                                          funcionarios.tipo_arquivo.descricao,
                                      })
                                    }
                                  >
                                    <DriveFolderUpload
                                      style={{ width: "20px" }}
                                    />{" "}
                                  </button>
                                </Tooltip>
                              </>
                            );
                          },
                        },
                      ]}
                      data={applyFilterAndSort(
                        funcionarios.funcionarios
                      )}
                      options={{
                        headerStyle: {
                          backgroundColor: "#F2F2F3",
                          fontSize: 12,
                          textAlign: "center",
                          borderLeft: "solid 0.5px white",
                        },
                      }}
                      // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
                    />
                  </Paper>
                </div>
              );
            })}
          </div>
        );
      })}
      <ModalDigitalizarArquivo
        openModal={modalUploadArquivo}
        closeModal={setModalUploadArquivo}
        pasta={pasta.pasta_id}
        tipo_de_arquivos={pasta.tipo_arquivo_id}
        arquivo_nome={pasta.tipo_arquivo_nome}
        descricao={pasta.categoria_descricao}
        updateList={LoadActionFuncionarios}
      />
    </ThemeProvider>
  );
}
export default FuncionariosSemArquivo;
