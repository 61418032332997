import * as types from './../types/funcionarios';

const INITIAL_STATE = {
  loading: false,

  funcionarios: [],
  page: Number(1),
  rows: 10,
  total: 0,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOAD_FUNCIONARIOS:
      return { ...state, funcionarios: action.payload };

    case types.LOADING_FUNCIONARIOS:
      return { ...state, loading: action.payload };

      case types.LOAD_FUNCIONARIOS_PAGE:
        return { ...state, page: action.payload };
        
      case types.LOAD_FUNCIONARIOS_PERPAGE:
        return { ...state, rows: action.payload };
        
      case types.LOAD_FUNCIONARIOS_TOTAL:
        return { ...state, total: action.payload };


    default:
      return state;
  }
};