export const SET_VINCULO_PESSOA_EMPRESA = 'SET_VINCULO_PESSOA_EMPRESA';
export const LOADING_VINCULOS = 'LOADING_VINCULOS';
export const SET_FUNCIONARIO_VINCULADO_INFO = 'SET_FUNCIONARIO_VINCULADO_INFO';


export const LOAD_VINCULOS_PFPJ_PAGE = 'LOAD_VINCULOS_PFPJ_PAGE';
export const LOAD_VINCULOS_PFPJ_PERPAGE = 'LOAD_VINCULOS_PFPJ_PERPAGE';
export const LOAD_VINCULOS_PFPJ_TOTAL = 'LOAD_VINCULOS_PFPJ_TOTAL';


export const FUNCIONARIOS_DISPONIVEIS_LOAD = 'FUNCIONARIOS_DISPONIVEIS_LOAD';
export const FUNCIONARIOS_DISPONIVEIS_TOTAL = 'FUNCIONARIOS_DISPONIVEIS_TOTAL';
export const FUNCIONARIOS_DISPONIVEIS_ROWS = 'FUNCIONARIOS_DISPONIVEIS_ROWS';
export const FUNCIONARIOS_DISPONIVEIS_PAGE = 'FUNCIONARIOS_DISPONIVEIS_PAGE';