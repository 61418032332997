import { Divider, Grid, Tooltip } from "@material-ui/core";
import { EditOutlined, Person, PersonOff } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { BiCategoryAlt } from "react-icons/bi";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatarNome } from "../../Helpers/formatarNome";
import AlertComponent from "../../components/AlertComponent";
import { DataGridTable, InputText } from "../../components/index";
import Modal from "../../components/modal";
import {
  editarCategoria,
  inserirCategoria,
  loadCategorias,
} from "../../store/actions/categoria_arquivos";
import useStyles from "./styles.jsx";

function CategoriasDeArquivos() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categorias } = useSelector((state) => state.categoria_arquivo);

  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);

  const [paginacao, setPaginacao] = useState({
    perpage: 10,
    total: 0,
    page: 0,
  });
  const [openDocsUpload, setOpenDocsUpload] = useState(false);
  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState({ descricao: "" });
  const [modal_insert, setModalInsert] = useState({ descricao: "" });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("");

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  const OpenDocs = () => {
    setOpenDocsUpload(openDocsUpload ? false : true);
  };
  const CloseDocs = () => {
    setOpenDocsUpload(openDocsUpload === false);
  };

  const fileInputRef = useRef();

  useEffect(() => {
    dispatch(loadCategorias());
  }, []);

  const handleChange = () => {
    // do something with event data
  };

  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data);
  }

  function CloseInsertModal() {
    setModalInsert({ nome: "", cpf_cnpj: "" });
    Close();
  }

  async function OnSubmitCategoria() {
    try {
      await inserirCategoria({
        descricao: formatarNome(modal_insert.descricao),
      });
      dispatch(loadCategorias());
      setMessageError("Categoria cadastrada com sucesso!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      CloseInsertModal();
    } catch (error) {
      setMessageError("Erro ao cadastrar categoria!");
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  async function OnSubmitEditCategoria(id) {
    try {
      await editarCategoria(
        { descricao: formatarNome(modal_edit.descricao) },
        id
      );
      dispatch(loadCategorias());
      setMessageError("Categoria editada com sucesso!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      setOpenEdit(false);
    } catch (error) {
      setMessageError("Erro ao editar categoria!");
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Cadastro de Arquivos:
        </h4>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item sm={8} xs={8}>
            {/* <InputDebounce
              label="Digite o nome ou CPF para pesquisar"
              value={dados.pesquisa}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => console.log("filtro")}
            /> */}
          </Grid>
          <Grid item sm={4} xs={4}>
            <div style={{ float: "right", marginRight: "1em" }}>
              <button
                onClick={Open}
                style={{
                  border: "none",
                  padding: "12px 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#060D13",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginTop: "0.9em",
                  fontSize: "10px",
                }}
              >
                <BiCategoryAlt style={{ fontSize: "16px" }} />
                &nbsp; INSERIR CATEGORIA
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar>
            <DataGridTable
              columns={[
                {
                  title: "Data de criação",
                  cellStyle: { width: "20%", textAlign: "center" },
                  render: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
                },
                { title: "Descrição", field: "descricao" },
                {
                  title: "Status",
                  field: "status",
                  render: (row) => (row.status == "A" ? "Ativo" : "Inativo"),
                  cellStyle: { width: "15%", textAlign: "center" },
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%", textAlign: "center" },
                  render: (row) => {
                    return (
                      <>
                        {/* <Tooltip title="Desativar Funcionário">
                   <button style={{cursor: 'pointer', background: 'transparent', border: 'none'}} onClick={() => DisabledFuncionario(row.id)}><PersonAddDisabled style={{width: '16px'}}/> </button>
                   </Tooltip> &nbsp; */}
                        <Tooltip title="Editar Arquivo">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() => OpenEditModal(row)}
                          >
                            <EditOutlined style={{ width: "22px" }} />{" "}
                          </button>
                        </Tooltip>
                        <Tooltip title="Funcionários com o arquivo">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() =>
                              navigate(`/app/categoria-arquivos/${row.id}/true`)
                            }
                          >
                            <Person style={{ width: "22px" }} />{" "}
                          </button>
                        </Tooltip>
                        <Tooltip title="Funcionários sem o arquivo">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() =>
                              navigate(`/app/categoria-arquivos/${row.id}/false`)
                            }
                          >
                            <PersonOff style={{ width: "22px" }} />{" "}
                          </button>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              data={categorias}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
              }}
              // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
            />
            {/* <TablePaginate
              rowsPerPageOptions={[10, 30, 50, 100]}
              rowsPerPage={parseInt(perpage)}
              count={parseInt(total)}
              page={parseInt(page) - 1}
              onChangePage={(e, newPage) => handleChangePage(newPage)}
              onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            /> */}
          </PerfectScrollBar>
        </Grid>

        {showSuccessAlert && (
          <AlertComponent
            type="success"
            message={messageError}
            timeout={3000}
          />
        )}

        {showErrorAlert && (
          <AlertComponent type="error" message={messageError} timeout={3000} />
        )}

        <Modal open={open} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>
            Insira as informações para adicionar categoria de arquivo
          </h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Descrição"
            value={modal_insert.descricao}
            onChange={(e) =>
              setModalInsert({ ...modal_insert, descricao: e.target.value })
            }
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />
          <div style={{ float: "right" }}>
            <button
              onClick={OnSubmitCategoria}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              {" "}
              Cadastrar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => CloseInsertModal()}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              {" "}
              Fechar
            </button>
          </div>
        </Modal>
        <Modal open={open_edit} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>
            Insira as informações para editar a categoria de arquivo
          </h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Descrição"
            value={modal_edit.descricao}
            onChange={(e) =>
              setModalEdit({ ...modal_edit, descricao: e.target.value })
            }
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />
          <div style={{ float: "right" }}>
            <button
              onClick={() => OnSubmitEditCategoria(modal_edit.id)}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              {" "}
              Editar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => setOpenEdit(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              {" "}
              Fechar
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default CategoriasDeArquivos;
