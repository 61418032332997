import {
  AppBar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  makeStyles,
  Popover,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { BiBuildings, BiUserCircle } from "react-icons/bi";
import { MdOutlineSettings } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../assets/images/support_dash.png";

const useStyles = makeStyles((theme) => ({
  togglerSidebar: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  headerIcons: {
    display: "none",
    color: "#fff",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const { usuario, loading } = useSelector((state) => state.usuario);

  function handleSignOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("usuario");
    localStorage.removeItem("empresa_id");
    localStorage.removeItem("permissoes");
    navigate("/", { replace: true });
  }



  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [permissions, setPermissions] = useState();

  useEffect(() => {
    const _permissoes = localStorage.getItem("permissoes");
    setPermissions(_permissoes);
  }, []);

  return (
    <AppBar
      // color="inherit"
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <img src={logo} alt="logo" style={{ width: "8%", height: "auto" }} />

        <Box flexGrow={1} />
        {permissions?.includes(8) || permissions?.includes(9) ? (
          <Tooltip title="Configurações">
            <Button onClick={handleClick} style={{ color: "#fff" }}>
              <MdOutlineSettings size={20} />
              &nbsp; <KeyboardArrowDown />
            </Button>
          </Tooltip>
        ) : (
          ""
        )}
        <div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={{ padding: "0.5em" }}>
              <h5 style={{ color: "#060D13" }}> {usuario.nome} </h5>
              <Divider />
              <h6
                style={{
                  fontWeight: "normal",
                  color: "gray",
                  marginTop: "1em",
                  marginBottom: 0,
                }}
              >
                Cadastro
              </h6>
              <div
                style={{ marginLeft: "6px", paddingRight: "4em", marginTop: 3 }}
              >
                {permissions?.includes(8) && (
                  <Link
                    to="/app/usuarios"
                    style={{
                      color: "#343d3f",
                      textDecoration: "none",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "0.5em",
                    }}
                  >
                    <BiUserCircle />
                    &nbsp; Cadastro de Usuários{" "}
                  </Link>
                )}
                {permissions?.includes(9) && (
                  <Link
                    to="/app/empresa"
                    style={{
                      color: "#343d3f",
                      textDecoration: "none",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                    }}
                  >
                    <BiBuildings />
                    &nbsp; Cadastro de Empresas{" "}
                  </Link>
                )}
              </div>
              {/* <h6 style={{ fontWeight: "normal", color: "gray", marginTop: "1em" }} >
            Geral
          </h6>
          <div style={{marginLeft: '6px', lineHeight: '1.7em'}}>
          <Link to="/dashboard/credenciados" style={{color: '#343d3f'}}> Credenciados </Link>
          </div> */}
            </div>
          </Popover>
        </div>
        <Link to="/">
          <Tooltip title="Sair">
            <Button onClick={handleSignOut} style={{ color: "#fff" }}>
              <InputIcon />
            </Button>
          </Tooltip>
        </Link>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <div
        style={{
          height: "3px",
          position: "absolute",
          width: "100%",
          bottom: "0",
          left: "0",
          backgroundImage: `linear-gradient(to right, #f9fdff, #43a0cc)`,
        }}
      ></div>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
