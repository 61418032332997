import * as types from './../types/vinculo_pessoa_empresa';

const INITIAL_STATE = {
  loading: false,

  vinculo_pessoa_empresa: [],
  funcionarioVinculado: {},

  page: Number(1),
  rows: 10,
  total: 0,
  funcionariosDisponiveis: [],
  funcionariosPage: Number(1),
  funcionarioDisponiveisTotal: 0,
  funcionarioDisponiveisRows: 10,
  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_VINCULO_PESSOA_EMPRESA:
      return { ...state, vinculo_pessoa_empresa: action.payload };

    case types.SET_FUNCIONARIO_VINCULADO_INFO:
      return { ...state, funcionarioVinculado: action.payload };

    case types.LOADING_VINCULOS:
      return { ...state, loading: action.payload };


      case types.LOAD_VINCULOS_PFPJ_PAGE:
        return { ...state, page: action.payload };
        
      case types.LOAD_VINCULOS_PFPJ_PERPAGE:
        return { ...state, rows: action.payload };
        
      case types.LOAD_VINCULOS_PFPJ_TOTAL:
        return { ...state, total: action.payload };


      case types.FUNCIONARIOS_DISPONIVEIS_LOAD:
        return { ...state, funcionariosDisponiveis: action.payload };
        
      case types.FUNCIONARIOS_DISPONIVEIS_TOTAL:
        return { ...state, funcionarioDisponiveisTotal: action.payload };
        
      case types.FUNCIONARIOS_DISPONIVEIS_ROWS:
        return { ...state, funcionarioDisponiveisRows: action.payload };

        
      case types.FUNCIONARIOS_DISPONIVEIS_PAGE:
        return { ...state, funcionariosPage: action.payload };

    default:
      return state;
  }
};