import { Grid, Tooltip } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { BsInboxes } from "react-icons/bs";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGridTable } from "../../../components/index";
import { loadGrupoDeArquivos } from "../../../store/actions/grupoDeArquivos";
import { useState } from "react";

function TableGrupoDeArquivos({ openModal }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function OpenModalGrupoDeArquivos(data) {
    navigate(`/app/grupos/${data.descricao}/${data.id}`)
  }

  const { gruposDeArquivos } = useSelector((state) => state.grupos_de_arquivos);

  const [gruposArquivosLoad, setGruposArquivosLoad] = useState([])

  useEffect(() => {
    loadGrupoDeArquivos().then((resp) => {
      setGruposArquivosLoad(resp)
    })
  
  }, []);

  return (
    <Grid sm={12} style={{ marginTop: "2em" }}>

{gruposDeArquivos.length > 0 && 
      <PerfectScrollBar>

        <DataGridTable
          columns={[
            {
              title: "Data de criação",
              cellStyle: { width: "20%", textAlign: "center", fontSize: 12 },
              render: (row) => dayjs(row?.created_at).format("DD/MM/YYYY"),
            },
            { title: "Descrição", field: "descricao", cellStyle: { fontSize: 12 } },
            {
              title: "Status",
              field: "status",
              render: (row) => (row?.status ? "Ativo" : "Inativo"),
              cellStyle: { width: "15%", textAlign: "center", fontSize: 12 },
            },
            {
              title: "Ações",
              cellStyle: { width: "15%", textAlign: "center", fontSize: 12 },
              render: (row) => {
                return (
                  <>                 
                    <Tooltip title="Arquivos Obrigatórios">
                      <button
                        style={{
                          cursor: "pointer",
                          background: "transparent",
                          border: "none",
                        }}
                        onClick={() => OpenModalGrupoDeArquivos(row)}
                      >
                        <BsInboxes style={{ width: "16px" }} />{" "}
                      </button>
                    </Tooltip>
                    <Tooltip title="Editar Arquivo">
                      <button
                        style={{
                          cursor: "pointer",
                          background: "transparent",
                          border: "none",
                        }}
                        onClick={() => openModal(row)}
                      >
                        <EditOutlined style={{ width: "16px" }} />{" "}
                      </button>
                    </Tooltip>
                  </>
                );
              },
            },
          ]}
          data={gruposDeArquivos}
          style={{
            fontSize: 13
          }}
          options={{
            headerStyle: {
              backgroundColor: "#F2F2F3",
              fontSize: 12,
              textAlign: "center",
              borderLeft: "solid 0.5px white",
            },
          }}
        />

      </PerfectScrollBar>
    }

    </Grid>
  );
}

export default TableGrupoDeArquivos;
