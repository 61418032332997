import {
    Button,
    Divider, Table,
    TableBody,
    TableCell,
    TableContainer, TableHead, TableRow, TextField
} from "@material-ui/core";
import faker from "faker-br";
import React, { useEffect, useState } from "react";
import { Save, XCircle } from "react-feather";
import { Link } from 'react-router-dom';
import Modal from "./../../../../components/modal/index";
import useStyles from "./styles";

function ViewGrupo() {
  const classes = useStyles();

  const [dados, setDados] = useState([]);

  const [open, setOpen] = useState(false);

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  const generate = () => {
    let item = [];
    for (let i = 0; i < 2; i++) {
      item.push(
        {
          id: faker.random.number(),
          nome: faker.company.companyName(),
          cnpj: faker.br.cnpj(),
          acao: "Editar",
        },
        {
          id: faker.random.number(),
          nome: faker.company.companyName(),
          cnpj: faker.br.cnpj(),
          acao: "Login",
        }
      );
      setDados(item);
    }
    item.sort((a, b) => (a.nome_razao < b.nome_razao ? -1 : 1));
  };

  useEffect(() => {
    generate();
  }, []);

  return (
    <>
      <div className={classes.prontuarios}>
        <div className={classes.prontuariosContainer}>
          <div className={classes.center}>
            <h1>Empresa</h1>
          </div>
          <Divider />
          <br />
          <b>Nome</b>: Kliklabs
          <br />
          <b>Descrição</b>:
          <br />
          <br />
          <Divider />
          <br />
          <div style={{ float: "right" }}>
            <Button
              style={{
                backgroundColor: "#060D13",
                color: "#fff",
              }}
              onClick={() => Open()}
            >
              Editar Grupo
            </Button>
            &nbsp;
            <Button
              style={{
                backgroundColor: "#1DA7A4",
                color: "#fff",
              }}
              onClick={() => Open()}
            >
              Adicionar Empresa
            </Button>
          </div>
          <br />
          <br />
          <br />
          <br />
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Cnpj</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.map((item) => {
                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.nome}</TableCell>
                        <TableCell>{item.cnpj}</TableCell>
                        <TableCell>
                          <Link to="/app/operador/grupos/open">
                          <button className={classes.btnEdit}>
                           
                            Abrir
                          </button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            style={{ padding: "20px 30px", width: "40%" }}
            title="Editar Cliente"
            subTitle="Edite os dados do cadastro"
            buttons={[
              {
                title: "Cancelar",
                icon: <XCircle height="1.5em" width="1.5em" />,
                style: { color: "red", fontSize: "14px" },
                onClick: () => Close(),
              },
              {
                title: "Editar",
                icon: <Save height="1.5em" width="1.5em" />,
                style: { color: "green", fontSize: "14px" },
                onClick: () => console.log("Salvou"),
              },
            ]}
          >
            <br />
            <TextField
              fullWidth
              label="Razão Social/Nome"
              id="fullWidth"
              value="Support Empresarial"
              margin="dense"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Descrição"
              margin="dense"
              variant="outlined"
            />
            <br />
          </Modal>
        </div>
      </div>
    </>
  );
}

export default ViewGrupo;
