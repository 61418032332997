import * as types from "./../types/tipos_de_arquivos";

const INITIAL_STATE = {
  loading: false,
  tipo_de_arquivos: [],
  listaPorCategoria: [],
  page: Number(1),
  rows: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TIPO_ARQUIVO:
      return { ...state, tipo_de_arquivos: action.payload };

    case types.LOADING_TIPO_ARQUIVO:
      return { ...state, loading: action.payload };

    case types.SET_LISTA_POR_CATEGORIA:
      return { ...state, listaPorCategoria: action.payload };

      
      case types.LOAD_TIPO_DE_ARQUIVO_PAGE:
        return { ...state, page: action.payload };
        
      case types.LOAD_TIPO_DE_ARQUIVO_PERPAGE:
        return { ...state, rows: action.payload };
        
      case types.LOAD_TIPO_DE_ARQUIVO_TOTAL:
        return { ...state, total: action.payload };


    default:
      return state;
  }
};


