import { applyMiddleware, combineReducers, createStore } from "redux";
import multi from "redux-multi";
import thunk from "redux-thunk";

import {
  arquivos,
  categoria_arquivo,
  empresas,
  enderecos,
  funcionarios,
  grupos_de_arquivos,
  home,
  pastas,
  tipo_de_arquivos,
  usuario,
  vinculo_pessoa_empresa,
} from "./reducers";

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
  usuario,
  home,
  funcionarios,
  empresas,
  categoria_arquivo,
  vinculo_pessoa_empresa,
  tipo_de_arquivos,
  enderecos,
  pastas,
  grupos_de_arquivos,
  arquivos,
});

export default applyMiddleware(thunk, multi)(createStore)(
  reducers,
  process.env.NODE_ENV === "development" ? devTools : undefined
);
