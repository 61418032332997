import { Divider, Grid, InputAdornment, Tooltip } from "@material-ui/core";
import { Clear, EditOutlined, PersonAddDisabled } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Search, Send } from "react-feather";
import { BsPeople } from "react-icons/bs";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatCNPJ, formatCPF } from "../../Helpers";
import { formatarNome } from "../../Helpers/formatarNome";
import { removerCaracteres } from "../../Helpers/removeDots";
import {
  DataGridTable,
  DialogComponent,
  InputText,
  TablePaginate,
} from "../../components/index";
import Modal from "../../components/modal";
import {
  disabledFuncionario,
  editarFuncionario,
  inserirFuncionario,
  loadFuncionarios,
} from "../../store/actions/funcionarios";
import * as types from "../../store/types/funcionarios";
import useStyles from "./styles.jsx";
import AlertComponent from "../../components/AlertComponent";

function Funcionarios() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { funcionarios, rows, page, total } = useSelector(
    (state) => state.funcionarios
  );

  const [open, setOpen] = useState(false);

  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState({ nome: "", cpf_cnpj: "" });
  const [modal_insert, setModalInsert] = useState({ nome: "", cpf_cnpj: "" });
  const [open_dialog, setDialog] = useState(false);
  const [disabled_funcionario, setDisabledFunc] = useState({});
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [buscaFuncionarioDebounce, setBuscaFuncionarioDebounce] = useState("");
  const [errorMessageUsuario, setErrorMessageUsuario] = useState(false);
  const [errorNomeNull, setErrorNomeNull] = useState(false);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("");

  const handleInputChange = (e) => {
    const valor = e.target.value;
    setNomeFuncionario(valor);
  };

  useEffect(() => {
    const salvarNomeFuncionario = () => {
      setBuscaFuncionarioDebounce(nomeFuncionario);
    };
    const debounceTimeout = 800;
    let timeoutId;

    const handleChange = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        salvarNomeFuncionario();
      }, debounceTimeout);
    };

    handleChange();

    return () => clearTimeout(timeoutId);
  }, [nomeFuncionario]);

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  useEffect(() => {
    dispatch(
      loadFuncionarios({
        page: Number(1),
        rows,
        status: "A",
        filter: buscaFuncionarioDebounce,
      })
    );
  }, [buscaFuncionarioDebounce]);

  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data);
  }

  function CloseInsertModal() {
    setModalInsert({ nome: "", cpf_cnpj: "" });
    Close();
  }

  async function OnSubmitFunctionario() {
    try {
      if (modal_insert.nome.length > 0) {
        await inserirFuncionario({
          nome: formatarNome(modal_insert.nome),
          cpf_cnpj: removerCaracteres(modal_insert.cpf_cnpj),
        });
        setMessageError("Funcionário cadastrado com sucesso!");
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
        dispatch(loadFuncionarios());
        CloseInsertModal();
      } else {
        setMessageError("Erro ao cadastrar funcionário!");
        setErrorNomeNull(true);
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 3000);
      }
    } catch (error) {
      setErrorMessageUsuario(true);
    }
  }

  async function DisabledFuncionario() {
    try {
      await disabledFuncionario(disabled_funcionario.id);
      dispatch(loadFuncionarios());
      setDialog(false);
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  async function DesabilitarFuncionario(data) {
    try {
      await setDialog(true);
      setDisabledFunc(data);
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  async function OnSubmitEditFunctionario(id) {
    try {
      await editarFuncionario(
        { nome: formatarNome(modal_edit.nome), cpf: modal_edit.cpf_cnpj },
        id
      );
      dispatch(loadFuncionarios());
      setMessageError("Funcionário editado com sucesso!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      setOpenEdit(false);
    } catch (error) {
      setMessageError("Erro ao editar funcionário!")
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
      setErrorMessageUsuario(true);
    }
  }

  if (errorMessageUsuario) {
    setTimeout(() => {
      setErrorMessageUsuario(false);
    }, 4000);
  }
  if (errorNomeNull) {
    setTimeout(() => {
      setErrorNomeNull(false);
    }, 4000);
  }

  const [carrega, setCarrega] = useState(false);

  const handleChangePage = async (event, newPage) => {
    setCarrega(true);
    await dispatch([
      {
        type: types.LOAD_FUNCIONARIOS_PAGE,
        payload: parseInt(newPage) + Number(1),
      },
      loadFuncionarios({ page: newPage + Number(1), rows }),
    ]);
    try {
      setCarrega(false);
    } catch (error) {
      setCarrega(false);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setCarrega(true);
    await dispatch([
      { type: types.LOAD_FUNCIONARIOS_PAGE, payload: Number(1) },
      {
        type: types.LOAD_FUNCIONARIOS_PERPAGE,
        payload: parseInt(event.target.value),
      },
      loadFuncionarios({ page: Number(1), rows: parseInt(event.target.value) }),
    ]);
    try {
      setCarrega(false);
    } catch (error) {
      setCarrega(false);
    }
  };

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Cadastro de Funcionários:
        </h4>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item sm={8} xs={8}>
            <InputText
              label="Digite o Nome ou CPF completo sem pontos para pesquisar"
              value={nomeFuncionario}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item sm={4} xs={4}>
            <div style={{ float: "right", marginRight: "1em" }}>
              <button
                onClick={Open}
                style={{
                  border: "none",
                  padding: "12px 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#060D13",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginTop: "0.9em",
                  fontSize: "10px",
                }}
              >
                <BsPeople style={{ fontSize: "16px" }} />
                &nbsp; ADICIONAR FUNCIONÁRIO
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar>
            <DataGridTable
              columns={[
                {
                  title: "Data de criação",
                  cellStyle: { width: "20%", textAlign: "center" },
                  render: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
                },
                {
                  title: "Nome",
                  field: "nome",
                  render: (row) => (
                    <div
                      style={{
                        maxWidth: "30ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {" "}
                      {row.nome}
                    </div>
                  ),
                },
                {
                  title: "CPF/CNPJ",
                  cellStyle: { width: "25%", textAlign: "center" },
                  render: ({ cpf_cnpj }) =>
                    cpf_cnpj &&
                    (cpf_cnpj.length === 11
                      ? formatCPF(cpf_cnpj)
                      : formatCNPJ(cpf_cnpj)),
                },
                {
                  title: "Status",
                  field: "status",
                  render: (row) => (row.status == "A" ? "Ativo" : "Inativo"),
                  cellStyle: { width: "15%", textAlign: "center" },
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%", textAlign: "center" },
                  render: (row) => {
                    return (
                      <>
                        <Tooltip title="Desativar Funcionário">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() => DesabilitarFuncionario(row)}
                          >
                            <PersonAddDisabled style={{ width: "16px" }} />{" "}
                          </button>
                        </Tooltip>{" "}
                        &nbsp;
                        <Tooltip title="Editar Funcionário">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() => OpenEditModal(row)}
                          >
                            <EditOutlined style={{ width: "16px" }} />{" "}
                          </button>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              data={funcionarios.data}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
              }}
              // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
            />
            <TablePaginate
              style={{ overflowX: "hidden" }}
              rowsPerPageOptions={[10, 20, 30]}
              rowsPerPage={+rows}
              count={+total}
              page={+page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PerfectScrollBar>
        </Grid>

        {showSuccessAlert && (
          <AlertComponent
            type="success"
            message={messageError}
            timeout={3000}
          />
        )}

        {showErrorAlert && (
          <AlertComponent type="error" message={messageError} timeout={3000} />
        )}

        <Modal open={open} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>
            Insira as informações para adicionar um funcionário
          </h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Nome"
            value={modal_insert.nome}
            onChange={(e) =>
              setModalInsert({ ...modal_insert, nome: e.target.value })
            }
          />

          <InputText
            label="CPF"
            value={formatCPF(modal_insert.cpf_cnpj)}
            onChange={(e) =>
              setModalInsert({ ...modal_insert, cpf_cnpj: e.target.value })
            }
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          {errorNomeNull && (
            <b style={{ color: "red", paddingBottom: 5 }}>
              O nome não foi preenchido
              <br />
            </b>
          )}
          {errorMessageUsuario && (
            <b style={{ color: "red", paddingBottom: 5 }}>
              Já existe um funcionário cadastrado com o CPF informado
              <br />
            </b>
          )}
          <div style={{ float: "right" }}>
            <button
              onClick={OnSubmitFunctionario}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              Cadastrar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => CloseInsertModal()}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              Fechar
            </button>
          </div>
        </Modal>
        <Modal open={open_edit} style={{ padding: "20px 30px", width: "40%" }}>
          <h6 style={{ margin: 0 }}>
            Insira as informações para editar o funcionário
          </h6>
          <Divider />
          <Grid item xs={12} sm={12}>
            <InputText
              label="NOME"
              value={modal_edit.nome}
              onChange={(e) =>
                setModalEdit({ ...modal_edit, nome: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputText
              label="CPF"
              value={
                modal_edit.cpf_cnpj
                  ? formatCPF(modal_edit.cpf_cnpj)
                  : modal_edit.cpf_cnpj
              }
              onChange={(e) =>
                setModalEdit({ ...modal_edit, cpf_cnpj: e.target.value })
              }
            />
          </Grid>
          <Divider />
          <div style={{ float: "right" }}>
            <button
              onClick={() => OnSubmitEditFunctionario(modal_edit.id)}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              Editar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => setOpenEdit(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              Fechar
            </button>
          </div>
        </Modal>
        <DialogComponent
          open={open_dialog}
          title="Confirmar exclusão do Funcionário"
          contenttext={
            <>
              Deseja realmente excluir o funcionário {disabled_funcionario.nome}
            </>
          }
          buttons={[
            {
              title: "Cancelar",
              icon: <Clear />,
              style: { color: "gray" },
              onClick: () => {
                setDialog(false);
              },
            },
            {
              title: "Desabilitar funcionário",
              icon: <Send />,
              style: { color: "green" },
              onClick: DisabledFuncionario,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Funcionarios;
