import React, { useEffect, useState } from "react";

import { Divider, Grid, InputAdornment } from "@material-ui/core";
import dayjs from "dayjs";
import { Search } from "react-feather";
import { BsPeople } from "react-icons/bs";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import AlertComponent from "../../components/AlertComponent";
import {
  DataTable,
  InputDate,
  InputSelect,
  InputText,
  TablePaginate,
} from "../../components/index";
import Modal from "../../components/modal";
import { loadempresa } from "../../store/actions/empresas";
import {
  loadFuncionarios
} from "../../store/actions/funcionarios";
import {
  editarVinculos,
  loadVinculos,
} from "../../store/actions/vinculo_pessoa_empresa";
import * as types from "../../store/types/vinculo_pessoa_empresa";
import ChooseConfig from "./components";
import useStyles from "./styles.jsx";


function VinculoPessoaEmpresa() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vinculo_pessoa_empresa, rows, page, total } = useSelector(
    (state) => state.vinculo_pessoa_empresa
  );
  const { funcionarios } = useSelector((state) => state.funcionarios);

  const [open, setOpen] = useState(false);
  const [carrega, setCarrega] = useState(false);

  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState("");
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [buscaFuncionarioDebounce, setBuscaFuncionarioDebounce] = useState("");

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("");

  const handleInputChange = (e) => {
    const valor = e.target.value;
    setNomeFuncionario(valor);
  };

  useEffect(() => {
    const salvarNomeFuncionario = () => {
      setBuscaFuncionarioDebounce(nomeFuncionario);
    };
    const debounceTimeout = 800;
    let timeoutId;

    const handleChange = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        salvarNomeFuncionario();
      }, debounceTimeout);
    };

    handleChange();

    return () => clearTimeout(timeoutId);
  }, [nomeFuncionario]);

  useEffect(() => {
    dispatch(
      loadVinculos({
        page: Number(1),
        rows,
        status: "A",
        filter: buscaFuncionarioDebounce,
      })
    );
  }, [buscaFuncionarioDebounce]);

  const [selectData, setSelectData] = useState({
    status_operacional: "",
    data_admissao: "",
    data_termino: "",
    matricula: "",
  });



  useEffect(() => {
    dispatch([
      loadVinculos({ page: Number(1), rows, status: "A" }),
      loadempresa(),
      loadFuncionarios(),
    ]);
  }, []);



  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data.id);
    setSelectData({
      ...selectData,
      status_operacional: data.status_operacional,
      data_admissao: data.inicio_contrato,
      data_termino: data.fim_contrato,
      matricula: data.matricula,
    });
  }



 
  const validacao_fim_contrato =
    selectData.status_operacional == "A" ? null : selectData.data_termino;

  async function OnSubmitEditFunctionario(id) {
    try {
      await editarVinculos(modal_edit, {
        status_operacional: selectData.status_operacional,
        inicio_contrato: selectData.data_admissao,
        fim_contrato: validacao_fim_contrato,
        matricula: selectData.matricula,
      });
      dispatch(loadVinculos({ page: Number(1), rows, status: "A" }));
      setOpenEdit(false);
      setSelectData({ status_operacional: "T" });
      setMessageError("Vínculo editado com sucesso!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
    } catch (error) {
      setMessageError("Erro ao editar o vínculo!");
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  const VinculoData = vinculo_pessoa_empresa;
  const FuncionarioData = funcionarios.data;

  function encontrarRegistrosUnicos(data1, data2) {
    const resultados = [];

    for (let i = 0; i < data1?.length; i++) {
      if (!data2.some((elem) => elem.id === data1[i].funcionario_id)) {
        resultados.push(data1[i]);
      }
    }

    for (let i = 0; i < data2?.length; i++) {
      if (!data1.some((elem) => elem.funcionario_id === data2[i].id)) {
        resultados.push(data2[i]);
      }
    }

    return resultados;
  }

  const [funcionariosNaoSelecionados, setFuncionariosNaoSelecionados] =
    useState([]);

  const Open = () => {
    setOpen(open ? false : true);
    setFuncionariosNaoSelecionados(
      encontrarRegistrosUnicos(VinculoData, FuncionarioData)
    );
  };

  const handleChangePage = async (event, newPage) => {
    setCarrega(true);
    dispatch([
      {
        type: types.LOAD_VINCULOS_PFPJ_PAGE,
        payload: parseInt(newPage) + Number(1),
      },
      loadVinculos({ page: newPage + Number(1), rows }),
    ]);
    try {
      setCarrega(false);
    } catch (error) {
      setCarrega(false);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setCarrega(true);
    dispatch([
      { type: types.LOAD_VINCULOS_PFPJ_PAGE, payload: Number(1) },
      {
        type: types.LOAD_VINCULOS_PFPJ_PERPAGE,
        payload: parseInt(event.target.value),
      },
      loadVinculos({ page: Number(1), rows: parseInt(event.target.value) }),
    ]);
    try {
      setCarrega(false);
    } catch (error) {
      setCarrega(false);
    }
  };

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Vínculo de Funcionários e Empresas:
        </h4>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item sm={8} xs={8}>
            <Grid item sm={8} xs={8}>
              <InputText
                label="Digite o nome do funcionário para pesquisar"
                value={nomeFuncionario}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={4}>
            <div style={{ float: "right", marginRight: "1em" }}>
              <button
                onClick={Open}
                style={{
                  border: "none",
                  padding: "12px 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#060D13",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginTop: "0.9em",
                  fontSize: "10px",
                }}
              >
                <BsPeople style={{ fontSize: "16px" }} />
                &nbsp; GERAR VÍNCULO
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar style={{ fontSize: "15px" }}>
            <DataTable
              columns={[
                {
                  title: "Funcionário",
                  field: "nome",
                  cellStyle: { width: "30%" },
                },
                { title: "Matrícula", field: "matricula" },
                {
                  title: "Admissão",
                  render: (row) =>
                    dayjs(row.inicio_contrato)
                      .add(3, "hour")
                      .format("DD/MM/YYYY"),
                },
                {
                  title: "Rescisão",
                  cellStyle: {width: "15%" },
                  render: (row) =>
                    row.fim_contrato
                      ? dayjs(row.fim_contrato)
                          .add(3, "hour")
                          .format("DD/MM/YYYY")
                      : "",
                },
                {
                  title: "Status",
                  render: (row) =>
                    row.status_operacional == "D" ? "Desligado" : "Ativo",
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%" },
                  render: (row) => {
                    return (
                      <>
                        <button
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() => OpenEditModal(row)}
                        >
                          {/* <EditOutlined style={{ width: "16px" }} />{" "} */}
                          Editar
                        </button>
                        &nbsp;
                      </>
                    );
                  },
                },
              ]}
              data={vinculo_pessoa_empresa}
            />
            <TablePaginate
              style={{ overflowX: "hidden" }}
              rowsPerPageOptions={[5, 15, 30]}
              rowsPerPage={+rows}
              count={+total}
              page={+page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PerfectScrollBar>
        </Grid>

        {showSuccessAlert && (
          <AlertComponent
            type="success"
            message={messageError}
            timeout={3000}
          />
        )}

        {showErrorAlert && (
          <AlertComponent type="error" message={messageError} timeout={3000} />
        )}

        <Modal
          open={open}
          width="50%"
          style={{
            padding: "20px 30px",
            width: "50%",
            maxHeight: "70%",
            overflowY: "scroll",
          }}
        >
          <h6 style={{ margin: 0 }}>
            Selecione as informações para gerar um vínculo
          </h6>
          <Divider />
          <ChooseConfig
            showSuccessAlert={showSuccessAlert}
            showErrorAlert={showErrorAlert}
            messageError={messageError}
            setShowSuccessAlert={setShowSuccessAlert}
            setShowErrorAlert={setShowErrorAlert}
            setMessageError={setMessageError}
            funcionarios={funcionariosNaoSelecionados}
            close={() => setOpen(false)}
          />
          <Divider />
          <div style={{ float: "right" }}>
            <button
              onClick={() => setOpen(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              Fechar
            </button>
          </div>
        </Modal>

        <Modal open={open_edit} style={{ padding: "20px 30px", width: "40%" }}>
          <h6 style={{ margin: 0 }}>
            Insira as informações para editar o funcionário
          </h6>
          <Divider />
          <Grid item xs={12} sm={12}>
            <InputSelect
              name="status"
              label="Selecione o Status"
              values={[
                { value: "A", label: "Ativo" },
                { value: "D", label: "Desligado" },
              ]}
              value={selectData.status_operacional}
              onChange={(e) =>
                setSelectData({
                  ...selectData,
                  status_operacional: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputText
              name="Matricula"
              label="Matricula"
              value={selectData.matricula}
              onChange={(e) =>
                setSelectData({
                  ...selectData,
                  matricula: e.target.value,
                })
              }
            />
          </Grid>
          {selectData.status_operacional == "A" ? (
            <Grid item xs={12} sm={12}>
              <InputDate
                name="data_admissao"
                fullWidth
                label="Data de Admissão"
                value={dayjs(selectData.data_admissao)
                  .add(3, "hour")
                  .format("YYYY-MM-DD")}
                onChange={(d) =>
                  setSelectData({ ...selectData, data_admissao: d })
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={12}>
                <InputDate
                  name="data_admissao"
                  fullWidth
                  label="Data de Admissão"
                  value={dayjs(selectData.data_admissao)
                    .add(3, "hour")
                    .format("YYYY-MM-DD")}
                  onChange={(d) =>
                    setSelectData({ ...selectData, data_admissao: d })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputDate
                  name="data_termino"
                  fullWidth
                  label="Término do Contrato"
                  value={dayjs(selectData.data_termino).format("YYYY-MM-DD")}
                  onChange={(d) =>
                    setSelectData({ ...selectData, data_termino: d })
                  }
                />
              </Grid>
            </>
          )}
          <Divider />
          <div style={{ float: "right" }}>
            <button
              onClick={() => OnSubmitEditFunctionario(modal_edit)}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              Editar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => setOpenEdit(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              Fechar
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default VinculoPessoaEmpresa;
