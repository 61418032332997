import palette from '../pallete';

export default {
  root: {
    height: '35px',
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default
      }
    },
    padding: 0,
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 0,
  }
};