const formatarNome = (nome) => {
    const palavras = nome.toLowerCase().split(' ');
  
    const nomeFormatado = palavras.map((palavra) => {
      return palavra.charAt(0).toUpperCase() + palavra.slice(1);
    });
  
    return nomeFormatado.join(' ');
  }

  export { formatarNome };
  