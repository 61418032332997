import * as types from './../types/grupoDeArquivos';

const INITIAL_STATE = {
  loading: false,
  gruposDeArquivos: [],
  gruposCadastrados: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_GRUPO_DE_ARQUIVOS:
      return { ...state, gruposDeArquivos: action.payload };
      
    case types.SET_GRUPOS_CADASTRADOS:
      return { ...state, gruposCadastrados: action.payload };

    case types.LOADING_GRUPO_DE_ARQUIVOS:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};