import {
  Divider,
  Grid,
  Tooltip
} from "@material-ui/core";
import {
  EditOutlined
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { RiUserAddLine } from "react-icons/ri";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGridTable } from "../../components/index";
import { listar } from "../../store/actions/usuario";
import CriarUsuario from "./criarUsuario";
import EditarUsuario from "./editarUsuario";
import useStyles from "./styles";

function Usuarios() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, perpage, filtro, total, usuarios, dados_pessoais } =
    useSelector((state) => state.usuario);

  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(dados_pessoais);
  const [open_edit_modal, setOpenEdit] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  


  const Open = () => {
    setOpenAddUser(open ? false : true);
  };


  useEffect(() => {
    dispatch(listar({ ...filtro, page: 1, per_page: perpage }));
  }, []);

  const [dadosUsuarioSelecionado, setDadosUsuarioSelecionado] = useState({});

  function OpenEditModal(data) {
    setOpenEdit(true);
    const dadas = data.permissoes.split(",").map((s) => Number(s));
    setPermissoesSelecionadas(dadas);
    setDadosUsuarioSelecionado(data);
  }

  const [permissoesSelecionadas, setPermissoesSelecionadas] = useState([]);



  //
  return (
    <>
      <div className={classes.user}>
        <div className={classes.addUser}>
          <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
            Cadastro de Usuários:
          </h4>
          <Divider />
          <br />
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={8} xs={8}></Grid>
            <Grid item sm={4} xs={4}>
              <div style={{ float: "right", marginRight: "1em" }}>
                <button
                  onClick={Open}
                  style={{
                    border: "none",
                    padding: "12px 15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#060D13",
                    color: "#fff",
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginTop: "0.6em",
                    fontSize: "10px",
                  }}
                >
                  <RiUserAddLine style={{ fontSize: "16px" }} />
                  &nbsp; ADICIONAR 'USUÁRIO'
                </button>
              </div>
            </Grid>
          </Grid>

          <Grid sm={12} style={{ marginTop: "2em" }}>
            <PerfectScrollBar>
              <DataGridTable
                columns={[
                  // { title: "Data de Admissão", field: "date", cellStyle: { textAlign: "center" }, },
                  { title: "Funcionário", field: "nome" },
                  {
                    title: "Email",
                    field: "email",
                    cellStyle: { textAlign: "center" },
                  },
                  {
                    title: "Ações",
                    cellStyle: { width: "15%", textAlign: "center" },
                    render: (row) => {
                      return (
                        <>
                          <Tooltip title="Editar Usuario">
                            <button
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={() => OpenEditModal(row)}
                            >
                              <EditOutlined style={{ width: "16px" }} />{" "}
                            </button>
                          </Tooltip>
                        </>
                      );
                    },
                  },
                ]}
                data={usuarios}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
                // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
              />
            </PerfectScrollBar>
          </Grid>
        </div>
      </div>
   

      <CriarUsuario
        openModal={openAddUser}
        setOpenAdd={setOpenAddUser}
        setPermissoesSelecionadas={setPermissoesSelecionadas}
        permissoesSelecionadas={permissoesSelecionadas}
        usuarioSelecionado={dadosUsuarioSelecionado}
        filtro={filtro}
        perpage={perpage}
      />
      <EditarUsuario
        openModal={open_edit_modal}
        setOpenEdit={setOpenEdit}
        setPermissoesSelecionadas={setPermissoesSelecionadas}
        permissoesSelecionadas={permissoesSelecionadas}
        usuarioSelecionado={dadosUsuarioSelecionado}
      />
    </>
  );
}

export default Usuarios;
