import { CircularProgress, Divider, Grid, Tooltip } from "@material-ui/core";
import { Clear, DeleteOutline } from "@material-ui/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { DownloadCloud, Send } from "react-feather";
import { DataTable, DialogComponent, Modal } from "../../../components";
import {
  DownloadArquivo,
  ExcluirArquivoAWS,
  loadArquivos
} from "../../../store/actions/arquivos";

import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import AlertComponent from "../../../components/AlertComponent";

function ModalArquivosDigitalizados({
  open,
  arquivos,
  closeModal,
  idLista,
  permissions,
}) {
  const dispatch = useDispatch();
  const [baixar, setBaixar] = useState();
  const [excluirArquivo, setExcluirArquivo] = useState({});
  const [openDialogExclusaoArquivo, setOpenDialogExclusaoArquivo] =
    useState(false);
  const [listaDeArquivos, setListadeArquivos] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("")

  async function BaixarArquivoDiretorio(data) {
    setBaixar(data.id);
    const baixar = await DownloadArquivo(
      data.link.replace(/(.+)(.com\/)(.+)/g, "$3")
    );
    try {
      setBaixar();
    } catch (error) {
      setBaixar();
    }
  }

  async function ExcluirArquivo(data) {
    setExcluirArquivo(data);
    setOpenDialogExclusaoArquivo(true);
  }

  async function ExcluirArquivoConfirm() {
    try {
      await ExcluirArquivoAWS(
        excluirArquivo.id,
        excluirArquivo.link.replace(/(.+)(.com\/)(.+)/g, "$3")
      );
      setExcluirArquivo();
      setOpenDialogExclusaoArquivo(false);
      setMessageError("Arquivo removido com sucesso!")
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      dispatch([loadArquivos(idLista)]);
    } catch (error) {
      setExcluirArquivo();
      setOpenDialogExclusaoArquivo(false);
      setMessageError("Erro ao remover arquivo!")
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  return (
    <>
      <Modal
        open={open}
        width="100%"
        style={{
          padding: "20px 30px",
          maxHeight: "70%",
          overflowY: "scroll",
        }}
      >
        Lista arquivos:
        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar>
            <DataTable
              columns={[
                {
                  title: "Data Up",
                  cellStyle: { width: "10%", textAlign: "center" },
                  render: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
                },
                {
                  title: "Arquivo",
                  field: "tipo",
                  cellStyle: { width: "50%", },
                },
                {
                  title: "Descrição",
                  field: "nome",
                  cellStyle: { width: "20%", textAlign: "center" },
                },             
                {
                  title: "Ações",
                  cellStyle: { textAlign: "center" },
                  render: (row) => (
                    <>
                      {permissions?.includes(16) && (
                        <Tooltip title="Baixar arquivo">
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => BaixarArquivoDiretorio(row)}
                          >
                            {baixar == row.id ? (
                              <CircularProgress
                                size="18px"
                                style={{ marginRight: "1em", color: "#2B2B60" }}
                              />
                            ) : (
                              <DownloadCloud size="16" color="#060D13" />
                            )}
                          </button>
                        </Tooltip>
                      )}
                      {permissions?.includes(13) && (
                        <Tooltip title="Excluir arquivo">
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => ExcluirArquivo(row)}
                          >
                            {baixar == row.id ? (
                              ""
                            ) : (
                              <DeleteOutline
                                size="16"
                                style={{ color: "#820909" }}
                              />
                            )}
                          </button>
                        </Tooltip>
                      )}
                    </>
                  ),
                },
              ]}
              data={arquivos}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
              }}
              detailPanel={[
                {
                  tooltip: "Endereços",
                  render: (arq) => (
                    <>
                      Endereço Físico: {arq.endereco}
                    </>
                  )
                },
              ]}
              // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
            />
            {/* <TablePaginate
                  rowsPerPageOptions={[10, 30, 50, 100]}
                  rowsPerPage={parseInt(perpage)}
                  count={parseInt(total)}
                  page={parseInt(page) - 1}
                  onChangePage={(e, newPage) => handleChangePage(newPage)}
                  onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                /> */}
          </PerfectScrollBar>
        </Grid>
        <br />
        <Divider />
        <button 
          style={{
            padding: '7px 25px',
            backgroundColor: "transparent",
            borderRadius: '5px',
            cursor: "pointer",
            border: '1px solid #b51c1c',
            color: '#590909',
            float: 'right',
            marginTop: '10px'

          }}
        onClick={closeModal}>Fechar</button>
      </Modal>
      <div style={{ zIndex: 999, background: "red" }}>
        <DialogComponent
          open={openDialogExclusaoArquivo}
          title="Confirmar exclusão do arquivo?"
          style={{ zIndex: 999 }}
          contenttext={
            <>
             
              Deseja realmente excluir o arquivo? <br /> <b>Atenção!</b> Esta
              ação não poderá ser recuperada!
            </>
          }
          buttons={[
            {
              title: "Cancelar",
              icon: <Clear />,
              style: { color: "gray" },
              onClick: () => {
                setOpenDialogExclusaoArquivo(false);
              },
            },
            {
              title: "Excluir Arquivo",
              icon: <Send />,
              style: { color: "green" },
              onClick: ExcluirArquivoConfirm,
            },
          ]}
        />
      </div>
      {showSuccessAlert && (
        <AlertComponent type="success" message={messageError} timeout={3000} />
      )}

      {showErrorAlert && (
        <AlertComponent type="error" message={messageError} timeout={3000} />
      )}
    </>
  );
}

export default ModalArquivosDigitalizados;
