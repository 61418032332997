import { Divider } from "@material-ui/core";
import React, { useState } from "react";
import { BiCategoryAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatarNome } from "../../Helpers/formatarNome";
import { InputText } from "../../components/index";
import Modal from "../../components/modal";
import { EditarNomeGrupoArquivo, inserirGrupoDeArquivos, loadGrupoDeArquivos } from "../../store/actions/grupoDeArquivos";
import TableGrupoDeArquivos from "./components/tableGruposDeArquivos";
import useStyles from "./styles.jsx";
import AlertComponent from "../../components/AlertComponent";

function GruposDeArquivos() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);

  const [paginacao, setPaginacao] = useState({
    perpage: 10,
    total: 0,
    page: 0,
  });
  const [openDocsUpload, setOpenDocsUpload] = useState(false);
  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState({ descricao: "" });
  const [modal_insert, setModalInsert] = useState({ descricao: "" });


  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("")

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };



  const handleChange = () => {
    // do something with event data
  };

  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data);
  }

  function CloseInsertModal() {
    setModalInsert({ nome: "", cpf_cnpj: "" });
    Close();
  }

  async function OnSubmitCategoria() {
    try {
      await inserirGrupoDeArquivos({descricao: formatarNome(modal_insert.descricao)});
      loadGrupoDeArquivos()
      CloseInsertModal();
      setMessageError("Grupo cadastrado com sucesso!")
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);

    } catch (error) {
      setMessageError("Erro ao cadastrar grupo!")
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  async function OnSubmitEditCategoria(id) {
    try {
      await EditarNomeGrupoArquivo({descricao: formatarNome(modal_edit.descricao)}, id);
      loadGrupoDeArquivos()
      setMessageError("Grupo editado com sucesso!")
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      setOpenEdit(false);
    } catch (error) {
      setMessageError("Erro ao editar grupo!")
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Grupo de Arquivos:
        </h4>
        <Divider />
        <br />

        <div
          style={{
            alignItems: "center",
            display: "flex",
            alignContent: "center",
            justifyContent: "right",
          }}
        >
          <button
            onClick={Open}
            style={{
              border: "none",
              padding: "12px 15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#060D13",
              color: "#fff",
              cursor: "pointer",
              borderRadius: "5px",
              marginTop: "0.9em",
              fontSize: "10px",
              marginBottom: 50,
            }}
          >
            <BiCategoryAlt style={{ fontSize: "16px" }} />
            &nbsp; CRIAR GRUPO
          </button>
        </div>

        <TableGrupoDeArquivos
          openModal={OpenEditModal}
        />

        <Modal open={open} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>
            Insira as informações para criar um grupo de arquivos
          </h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Descrição"
            value={modal_insert.descricao}
            onChange={(e) =>
              setModalInsert({ ...modal_insert, descricao: e.target.value })
            }
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />
          <div style={{ float: "right" }}>
            <button
              onClick={OnSubmitCategoria}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              {" "}
              Cadastrar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => CloseInsertModal()}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              {" "}
              Fechar
            </button>
          </div>
        </Modal>
        <Modal open={open_edit} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>
            Insira as informações para editar o nome do grupo
          </h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Descrição"
            value={modal_edit.descricao}
            onChange={(e) =>
              setModalEdit({ ...modal_edit, descricao: e.target.value })
            }
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />
          <div style={{ float: "right" }}>
            <button
              onClick={() => OnSubmitEditCategoria(modal_edit.id)}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
              {" "}
              Editar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => setOpenEdit(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
              {" "}
              Fechar
            </button>
          </div>
        </Modal>
      </div>

      {showSuccessAlert && (
        <AlertComponent type="success" message={messageError} timeout={3000} />
      )}

      {showErrorAlert && (
        <AlertComponent type="error" message={messageError} timeout={3000} />
      )}
    </div>
  );
}

export default GruposDeArquivos;
