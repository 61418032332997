import { Divider, Grid, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
    AiFillDelete,
    AiOutlineAppstoreAdd,
    AiOutlineCloseSquare,
    AiOutlineMenu,
    AiOutlineSetting,
} from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { BsSave } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Toast } from "../../Helpers/ToastHelper";
import { formatarNome } from "../../Helpers/formatarNome";
import { InputText, Modal } from "../../components";
import { editarUsuario, listar } from "../../store/actions/usuario";

function EditarUsuario({
  permissoesSelecionadas,
  setPermissoesSelecionadas,
  openModal,
  setOpenEdit,
  usuarioSelecionado,
}) {
  const permissoesDisponiveis = [
    { id: 2, nome: "Documentos" },
    { id: 12, nome: "Fazer upload" },
    { id: 13, nome: "Excluir arquivo" },
    { id: 14, nome: "Criar pasta" },
    { id: 15, nome: "Ver informações de arquivos" },
    { id: 16, nome: "Baixar arquivos" },
    { id: 7, nome: "Cadastro de Funcionários" },
    { id: 10, nome: "Cadastro de Arquivos" },
    { id: 11, nome: "Vincular Funcionário e Empresa" },
    { id: 8, nome: "Cadastro de Usuários Plataforma" },
    { id: 9, nome: "Cadastro de Empresas" },
    { id: 17, nome: "Grupos de Arquivos" },
  ];
  const dispatch = useDispatch();
  const [editUsuario, setEditUsuario] = useState({ email: "", nome: "" });

  useEffect(() => {
    setEditUsuario({
      email: usuarioSelecionado.email,
      nome: usuarioSelecionado.nome,
    });
  }, [usuarioSelecionado]);

  const handleTogglePermissao = (id) => {
    const permissaoIndex = permissoesSelecionadas.indexOf(id);
    if (permissaoIndex === -1) {
      setPermissoesSelecionadas([...permissoesSelecionadas, id]);
    } else {
      const novaListaPermissoes = [...permissoesSelecionadas];
      novaListaPermissoes.splice(permissaoIndex, 1);
      setPermissoesSelecionadas(novaListaPermissoes);
    }
  };

  const permissoesUsuario = permissoesDisponiveis.filter((permissao) =>
    permissoesSelecionadas.includes(permissao.id)
  );

  async function OnSubmitEditUser() {
    const _permissoes = permissoesSelecionadas.join(",");

    try {
      await editarUsuario(
        {
          email: editUsuario.email,
          nome: formatarNome(editUsuario.nome),
          permissoes: _permissoes,
        },
        usuarioSelecionado.id
      );
      dispatch(listar());
      setOpenEdit(false);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Dados incompletos, revise os dados!",
      });
    }
  }

  return (
    <Modal open={openModal} width="100%">
      Editar Usuário
      <Divider />
      <>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={6} xs={6}>
            <div style={{ alignItems: "center", display: "flex" }}>
              <InputText
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                label="Nome"
                value={editUsuario.nome}
                onChange={(e) =>
                  setEditUsuario({ ...editUsuario, nome: e.target.value })
                }
              />
            </div>
          </Grid>

          <Grid item sm={6} xs={6}>
            <div style={{ alignItems: "center", display: "flex" }}>
              <InputText
                type="email"
                label="Email"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                placeholder="email@email.com"
                value={editUsuario.email}
                onChange={(e) =>
                  setEditUsuario({ ...editUsuario, email: e.target.value })
                }
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ height: 300, overflowY: "scroll", overflowX: "hidden" }}>
          <h5>Permissões do Usuário</h5>
          <Grid container spacing={1} alignItems="center">
            {permissoesUsuario.map((permissao) => (
              <Grid item sm={6} xs={6}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  key={permissao.id}
                >
                  <Grid item sm={8} xs={8}>
                    <div style={{ fontSize: 12 }}>
                      {permissao.id == 8 || permissao.id == 9 ? (
                        <Tooltip title="Configurações">
                          <span>
                            <AiOutlineSetting color="#c17420" />
                          </span>
                        </Tooltip>
                      ) : permissao.id == 2 ||
                        permissao.id == 7 ||
                        permissao.id == 10 ||
                        permissao.id == 17 ||
                        permissao.id == 11 ? (
                        <Tooltip title="Menu">
                          <span>
                            <AiOutlineMenu color="#083872" />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Sub-categorias">
                          <span>
                            <BiCategory color="#086d72" />
                          </span>
                        </Tooltip>
                      )}
                      {permissao.nome}{" "}
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={4}>
                    <button
                      style={{
                        color: "red",
                        backgroundColor: "#fff",
                        border: "1px solid red",
                        padding: "5px 15px",
                        borderRadius: 5,
                        cursor: "pointer",
                        fontSize: 12,
                      }}
                      onClick={() => handleTogglePermissao(permissao.id)}
                    >
                      <AiFillDelete /> Remover
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <h5>Adicionar Permissões</h5>
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ fontSize: 12 }}
          >
            {permissoesDisponiveis.map(
              (permissao) =>
                !permissoesSelecionadas.includes(permissao.id) && (
                  <Grid item sm={6} xs={6}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      key={permissao.id}
                      style={{ fontSize: 12 }}
                    >
                      <Grid item sm={8} xs={8}>
                        <div style={{ fontSize: 12 }}>
                          {permissao.id == 8 || permissao.id == 9 ? (
                            <Tooltip title="Configurações">
                              <span>
                                <AiOutlineSetting color="#c17420" />
                              </span>
                            </Tooltip>
                          ) : permissao.id == 2 ||
                            permissao.id == 7 ||
                            permissao.id == 17 ||
                            permissao.id == 10 ||
                            permissao.id == 11 ? (
                            <Tooltip title="Menu">
                              <span>
                                <AiOutlineMenu color="#083872" />
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sub-categorias">
                              <span>
                                <BiCategory color="#086d72" />
                              </span>
                            </Tooltip>
                          )}

                          {permissao.nome}
                        </div>
                      </Grid>

                      <Grid item sm={4} xs={4}>
                        <button
                          style={{
                            color: "green",
                            backgroundColor: "#fff",
                            border: "1px solid green",
                            padding: "5px 15px",
                            borderRadius: 5,
                            cursor: "pointer",
                            fontSize: 12,
                          }}
                          onClick={() => handleTogglePermissao(permissao.id)}
                        >
                          <AiOutlineAppstoreAdd /> Adicionar
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: 20,
          }}
        >
          <button
            style={{
              color: "#22a1a5",
              backgroundColor: "#fff",
              border: "1px solid #22a1a5",
              padding: "10px 25px",
              borderRadius: 5,
              fontSize: 14,
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => OnSubmitEditUser()}
          >
            <BsSave size={15} />
            &nbsp; Salvar Alterações
          </button>
          <button
            onClick={() => setOpenEdit(false)}
            style={{
              color: "#5e0f3f",
              backgroundColor: "#fff",
              border: "1px solid #5e0f3f",
              padding: "10px 25px",
              borderRadius: 5,
              fontSize: 14,
              marginLeft: 15,
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <AiOutlineCloseSquare size={15} />
            &nbsp;Fechar
          </button>
        </div>
      </>
    </Modal>
  );
}

export default EditarUsuario;
