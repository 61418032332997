import React from "react";
import { Navigate } from "react-router-dom";

import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/dashboard";
import Home from "./../pages/home/index";
import Login from "./../pages/Login/index";



import Logs from "../pages/relatorios/logs";

import RecursosHumanos from "../pages/arquivosFuncionarios";
import OpenFolderEmployee from "../pages/arquivosFuncionarios/openFolderEmployee";
import CategoriaArquivoNaoDigitalizado from "../pages/categoriaArquivosNaoDigitalizados";
import FuncionariosCategoriasGeral from "../pages/categoriaArquivosNaoDigitalizados/funcionariosSemArquivo";
import CategoriasDeArquivos from "../pages/categorias_arquivos";
import FuncionariosSemArquivoCategorias from "../pages/categorias_arquivos/funcionariosSemArquivo";
import Contabil from "../pages/contabil";
import Empresas from "../pages/empresas/index";
import Enderecos from "../pages/enderecos";
import Funcionarios from "../pages/funcionarios/index";
import GruposDeArquivos from "../pages/gruposDeArquivos";
import ArquivosObrigatoriosCadastro from "../pages/gruposDeArquivos/cadastrarArquivos";
import EsqueciSenha from "../pages/Login/resetSenha";
import SenhaLinkRecebido from "../pages/Login/resetSenha/linkRecebido";
import AddFuncionario from '../pages/operador/adicionar_funcionario/index';
import Grupos from '../pages/operador/grupos/index';
import OpenGrupo from '../pages/operador/grupos/openGrupo/index';
import UserGroups from '../pages/operador/grupos/openGrupo/usuarios/index';
import ViewGrupo from '../pages/operador/grupos/viewGrupo/index';
import TiposDeArquivos from "../pages/tipos_de_arquivos";
import FuncionariosSemArquivo from "../pages/tipos_de_arquivos/funcionariosSemArquivo/index";
import TiposDeArquivosGeral from "../pages/tiposDeArquivosGeral";
import FuncionariosSemArquivoGeral from "../pages/tiposDeArquivosGeral/funcionariosSemArquivo";
import Usuarios from "../pages/usuarios/index";
import VinculoPessoaEmpresa from "../pages/vinculo_pessoa_empresa/index";


const routes = [
  {
    path: "/app",
    element: <DashboardLayout />,
    children: [
      { path: "/home", element: <Home /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "*", element: <Navigate to="/app/home" /> },


      { path: "/contabil", element: <Contabil /> },


      { path: "/rh", element: <RecursosHumanos /> }, 
      { path: "/rh/arquivos/:idFuncionario", element: <OpenFolderEmployee /> }, 


,
  

      //categoria geral
      { path: "/categoria-arquivos-geral", element: <CategoriaArquivoNaoDigitalizado /> },
      { path: "/categoria-arquivos-geral/:id", element: <FuncionariosCategoriasGeral /> },



      { path: "/operador/grupos", element: <Grupos /> },
      { path: "/operador/grupos/view", element: <ViewGrupo /> },
      { path: "/operador/grupos/open", element: <OpenGrupo /> },
      { path: "/operador/grupos/open/users", element: <UserGroups /> },
      { path: "/operador/addfuncionario", element: <AddFuncionario /> },


      { path: "/usuarios", element: <Usuarios /> },
      { path: "/funcionarios", element: <Funcionarios /> },
      { path: "/empresa", element: <Empresas /> },
      { path: "/categoria-arquivos", element: <CategoriasDeArquivos /> },
      { path: "/categoria-arquivos/:id/:modalidade", element: <FuncionariosSemArquivoCategorias /> },


      { path: "/tipo-arquivos", element: <TiposDeArquivos /> },
      { path: "/tipo-arquivos/:id/:modalidade", element: <FuncionariosSemArquivo /> },
      
      
      
      { path: "/tipo-arquivos-geral", element: <TiposDeArquivosGeral /> },
      { path: "/tipo-arquivos-geral/:id", element: <FuncionariosSemArquivoGeral/> },



      { path: "/enderecos", element: <Enderecos /> },
      { path: "/vinculo", element: <VinculoPessoaEmpresa /> },
      { path: "/grupos", element: <GruposDeArquivos /> },
      { path: "/grupos/:group/:id", element: <ArquivosObrigatoriosCadastro /> },


      { path: "/relatorios/logs", element: <Logs /> },
    ],
  },

  {
    path: "/",
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/login", element: <Login /> },
      { path: "/esqueci", element: <EsqueciSenha /> },
      { path: "/recuperar-senha/:hash", element: <SenhaLinkRecebido /> },
    ],
  },
];

export default routes;
