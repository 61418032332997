import {
  Button, TextField
} from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import icone from "../../../assets/images/support_logo.png";
import { actionResetSenha } from "../../../store/actions/usuario";
  
  import { Box, Formulario, Home } from "../styles";
  
  function EsqueciSenha() {
    const dispatch = useDispatch();
    const [showPass, setShowPass] = useState(false);
    const { loadResetSenha } = useSelector((state) => state.usuario)
    function ShowPassword() {
      setShowPass(showPass ? false : true);
    }
  
    const navigate = useNavigate();
  
    const [data, setData] = useState({ email: "", password: "" });
  
    async function sendLogin(event) {
      event.preventDefault();
      try {
        await actionResetSenha(data.email);
        navigate("/login", { replace: true });
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <Home>
        <Box>
          <div style={{ textAlign: "center", padding: "1em" }}>
            <img src={icone} style={{ width: "150px", marginTop: 10 }} />
          </div>
          <h4 style={{ textAlign: "center" }}>Digite seu e-mail para recupar a senha!</h4>
  
          <Formulario onSubmit={sendLogin}>
            <label>
              <div>
                <TextField
                  type="email"
                  value={data.email}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  onChange={(event) =>
                    setData({
                      ...data,
                      email: event.target.value,
                    })
                  }
                  id="email"
                  label="E-mail"
                />
              </div>           
            </label>
            <br />
            <div>
            {loadResetSenha ? 
              <Button variant="outlined" fullWidth disabled="true" color="primary">
                Aguarde...
              </Button>
              :
              <Button variant="outlined" fullWidth type="submit" color="primary">
                Enviar Recuperação de Senha 
              </Button>
  }
            </div>
            <br />
          </Formulario>
          <div style={{ paddingBottom: "2em", textAlign: "center" }}>
  
          </div>
        
          <br />
        </Box>
      </Home>
    );
  }
  
  export default EsqueciSenha;
  