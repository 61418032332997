import { Divider, Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsSave } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AtualizarGrupoDeArquivos,
  CadastroGrupoDeArquivos,
  loadGruposCadastradosUsuario,
} from "../../store/actions/grupoDeArquivos";
import { loadTipodeArquivo } from "../../store/actions/tipos_de_arquivos";

function ArquivosObrigatoriosCadastro() {
  const dispatch = useDispatch();
  const { id, group } = useParams();
  const [tiposDeArquivosSelecionados, setTiposDeArquivosSelecionados] =
    useState([]);
  const { tipo_de_arquivos } = useSelector((state) => state.tipo_de_arquivos);
  const [arquivosDataLoad, setArquivosDataLoad] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    loadGruposCadastradosUsuario(id).then(async (resp) => {
      const arquivos = (await resp)
        ? resp?.map((item) => {
            return item.tipo_arquivo_id;
          })
        : [];
      setTiposDeArquivosSelecionados(arquivos);
      setArquivosDataLoad(resp);
    });
  }, []);

  useEffect(() => {
    dispatch(loadTipodeArquivo());
  }, []);

  const tiposDeArquivos = tipo_de_arquivos?.map((item) => {
    return { id: item.id, nome: item.descricao };
  });

  const onSubmitForm = async (values) => {
    try {
      if (arquivosDataLoad.length > 0) {
        await AtualizarGrupoDeArquivos(
          {
            ids: tiposDeArquivosSelecionados,
          },
          id
        );
        setTiposDeArquivosSelecionados([]);
        navigate(`/app/grupos`);
      } else {
        await CadastroGrupoDeArquivos({
          group_id: id,
          ids: tiposDeArquivosSelecionados,
        });
        setTiposDeArquivosSelecionados([]);
        navigate(`/app/grupos`);
      }
    } catch (error) {
      setTiposDeArquivosSelecionados([]);
    }
  };

  const handleTogglePermissao = (id) => {
    const permissaoIndex = tiposDeArquivosSelecionados.indexOf(id);
    if (permissaoIndex === -1) {
      setTiposDeArquivosSelecionados([...tiposDeArquivosSelecionados, id]);
    } else {
      const novaListaPermissoes = [...tiposDeArquivosSelecionados];
      novaListaPermissoes.splice(permissaoIndex, 1);
      setTiposDeArquivosSelecionados(novaListaPermissoes);
    }
  };

  const permissoesUsuario = tiposDeArquivos.filter((permissao) =>
    tiposDeArquivosSelecionados.includes(permissao.id)
  );

  return (
    <div>
      <h3>Grupo: {group}</h3>
      <button
        style={{
          color: "#5e0f3f",
          backgroundColor: "#fff",
          border: "1px solid #5e0f3f",
          padding: "5px 15px",
          marginBottom: 15,
          borderRadius: 5,
          fontSize: 14,
          marginLeft: 15,
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/app/grupos`)}
      >
        <ArrowBack /> Voltar para lista
      </button>
      <Divider />
      <>
        <div style={{ height: "100%", width: "90%", margin: "auto" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={6} xs={6}>
              <h4>Arquivos Obrigatórios do Grupo</h4>
              <div
                style={{
                  height: 350,
                  overflowY: "scroll",
                  textAlign: "left",
                  overflowX: "hidden",
                }}
              >
                {permissoesUsuario.map((permissao) => (
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    key={permissao.id}
                  >
                    <Grid item sm={8} xs={8}>
                      <span style={{ textAlign: "left", fontSize: 12 }}>
                        {permissao.nome}{" "}
                      </span>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <button
                        style={{
                          color: "red",
                          backgroundColor: "#fff",
                          border: "1px solid red",
                          padding: "5px 15px",
                          borderRadius: 5,
                          cursor: "pointer",
                          fontSize: 12,
                        }}
                        onClick={() => handleTogglePermissao(permissao.id)}
                      >
                        <AiFillDelete /> Remover
                      </button>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
            <Grid item sm={6} xs={6}>
              <h4>Adicionar Arquivos</h4>
              <div
                style={{
                  height: 350,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  textAlign: "left",
                }}
              >
                {tiposDeArquivos.map(
                  (permissao) =>
                    !tiposDeArquivosSelecionados.includes(permissao.id) && (
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        key={permissao.id}
                      >
                        <Grid item sm={8} xs={8}>
                          <span style={{ textAlign: "left", fontSize: 12 }}>
                            {permissao.nome}
                          </span>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <button
                            style={{
                              color: "green",
                              backgroundColor: "#fff",
                              border: "1px solid green",
                              padding: "5px 15px",
                              borderRadius: 5,
                              cursor: "pointer",
                              fontSize: 12,
                            }}
                            onClick={() => handleTogglePermissao(permissao.id)}
                          >
                            <AiOutlineAppstoreAdd /> Adicionar
                          </button>
                        </Grid>
                      </Grid>
                    )
                )}
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 20,
            }}
          >
            <button
              style={{
                color: "#22a1a5",
                backgroundColor: "#fff",
                border: "1px solid #22a1a5",
                padding: "5px 15px",
                borderRadius: 5,
                fontSize: 14,
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => onSubmitForm()}
            >
              <BsSave size={15} />
              &nbsp; Salvar Alterações
            </button>
          </div>
        </div>
      </>
    </div>
  );
}

export default ArquivosObrigatoriosCadastro;
