import { CircularProgress, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatCNPJ, formatCPF, toUpper } from "../../Helpers";
import {
  DataTable, InputSelect,
  InputText,
  Modal,
  TablePaginate
} from "../../components/index";
import { loadEnderecos } from "../../store/actions/enderecos";
import { loadPastas } from "../../store/actions/pastas";
import { loadTipodeArquivo } from "../../store/actions/tipos_de_arquivos";
import { loadVinculos } from "../../store/actions/vinculo_pessoa_empresa";
import * as types from "../../store/types/pastas";
import CriarPasta from "./components/criar_pasta";
import useStyles from "./styles";

function RecursosHumanos() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { pastas, rows, page, total, loading } = useSelector(
    (state) => state.pastas
  );

  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState();
  const [user, setUser] = useState();


  useEffect(() => {
    dispatch([
      { type: types.LOADING_PASTAS, payload: true },
      loadPastas({ page: Number(1), rows, status_operacional: "T" }),
      loadTipodeArquivo(),
      loadEnderecos(),
      loadVinculos()
    ]);
    const _permissoes = localStorage.getItem("permissoes");
    const _usuario = localStorage.getItem("usuario");
    setUser(_usuario);
    setPermissions(_permissoes);
  }, []);

 
  const [carrega, setCarrega] = useState(false);
  const [pesquisa, setPesquisa] = useState("");
  const [selectData, setSelectData] = useState({ status_operacional: "" });

  const handleChangePage = async (event, newPage) => {
    setCarrega(true);
     dispatch([
      { type: types.PASTAS_PAGE, payload: parseInt(newPage) + Number(1) },
      loadPastas({ page: newPage + Number(1), rows }),
    ]);
    try {
      setCarrega(false);
    } catch (error) {
      setCarrega(false);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setCarrega(true);
     dispatch([
      { type: types.PASTAS_PAGE, payload: Number(1) },
      { type: types.PASTAS_PERPAGE, payload: parseInt(event.target.value) },
      loadPastas({ page: Number(1), rows: parseInt(event.target.value) }),
    ]);
    try {
      setCarrega(false);
    } catch (error) {
      setCarrega(false);
    }
  };

  const [result, setResult] = useState("");

  async function PesquisarPorNome() {
    dispatch([
      loadPastas({
        page: Number(1),
        rows,
        status_operacional: selectData.status_operacional,
        employerName: pesquisa,
      }),
    ]);
    setResult(pesquisa);
    try {
      setPesquisa("");
    } catch {
      console.log("erro");
    }
  }

  console.log(selectData)

  async function LimparPesquisarPorNome() {
    await dispatch([
      loadPastas({ page: Number(1), rows, employerName: "", status_operacional: "T"  }),
    ]);
    setResult(pesquisa);
    try {
      setPesquisa("");
    } catch {
      console.log("erro");
    }
  }

  async function AcessarPastaFuncionario(data){   
    await dispatch([
      { type: types.PASTA_SELECT_EMPLOYEE, payload: data },
    ]);
    navigate(`/app/rh/arquivos/${data.funcionario?.id}`, { replace: true });
  }

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Documentos
        </h4>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={5} sm={5}>
            <InputText
              label="Pesquisa por nome"
              value={pesquisa}
              onChange={(e) => setPesquisa(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <InputSelect
              name="status"
              label="Status do funcionário"
              values={[
                { value: "T", label: "Todos" },
                { value: "L", label: "Ativo" },
                { value: "D", label: "Desligado" },
              ]}
              value={selectData.status_operacional}
              onChange={(e) =>
                setSelectData({
                  ...selectData,
                  status_operacional: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <button
              style={{
                border: "none",
                padding: "13px 17px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "transparent",
                border: "1px solid #6cb9c1",
                color: "#6cb9c1",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: "0.9em",
                fontSize: "10px",
              }}
              onClick={() => PesquisarPorNome()}
            >
              <Search size="12" />
              &nbsp; Pesquisar
            </button>
          </Grid>
         
        </Grid>
        <br />
        {result ? (
          <div style={{ textAlign: "left", fontSize: "14px" }}>
            Você pesquisou por <b>{toUpper(result)}</b> <br />
            <button
              style={{
                border: "1px solid red",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: "red",
                fontSize: "11px",
                padding: "6px",
                cursor: "pointer",
              }}
              onClick={() => LimparPesquisarPorNome()}
            >
              x Limpar Pesquisa
            </button>
          </div>
        ) : (
          ""
        )}

        <Grid sm={12} style={{ marginTop: "2em", height: "100%" }}>
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            {loading ? (
              <div
                style={{
                  height: "65vh",
                  width: "100%",
                  position: "absolute",
                  alignItems: "center",
                  backgroundColor: "#f2f2f7",
                  opacity: "0.5",
                  zIndex: "999",
                }}
              >
                <div
                  style={{
                    height: "60vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "999",
                  }}
                >
                  <CircularProgress
                    size="26px"
                    style={{ color: "#060D13", zIndex: 999 }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <PerfectScrollBar>
            <DataTable
              columns={[
                {
                  title: "Nome",
                  field: "funcionario.nome",
                  cellStyle: { textAlign: "center", fontSize: 12 },
                },
                {
                  title: "CPF/CNPJ",
                  field: "funcionario.cpf_cnpj",
                  cellStyle: { textAlign: "center", fontSize: 12 },
                  render: (row) =>
                    row.funcionario.cpf_cnpj &&
                    (row.funcionario.cpf_cnpj.length === 11
                      ? formatCPF(row.funcionario.cpf_cnpj)
                      : formatCNPJ(row.funcionario.cpf_cnpj)),
                },
                {
                  title: "Acessar Arquivos",
                  cellStyle: { textAlign: "center", fontSize: 12 },
                  render: (row) => {
                    return(
                      <button  
                      style={{
                        padding: '7px 25px',
                        backgroundColor: "transparent",
                        borderRadius: '5px',
                        cursor: "pointer",
                        border: '1px solid #10b23e',
                        color: '#085b1f'

                      }}
                      onClick={() => AcessarPastaFuncionario(row)}>
                        Acessar
                      </button>
                    )
                  }
                   
                },
              ]}
              data={pastas}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center", fontSize: 12,
                  borderLeft: "solid 0.5px white",
                },
                rowStyle: (rowData) => ({
                  fontSize: 14,
                  textAlign: "center", fontSize: 12,
                }),
              }}
           
            />

            <TablePaginate
              style={{ overflowX: "hidden" }}
              rowsPerPageOptions={[10, 20, 30]}
              rowsPerPage={+rows}
              count={+total}
              page={+page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PerfectScrollBar>
        </Grid>

        {/* ------------> CRIAÇÃO DE PASTA DE ARQUIVO : VINCULO DE FUNCIONARIO EMPRESA E PASTA <----------- */}

        <Modal
          open={open}
          width="50%"
          style={{
            padding: "20px 30px",
            width: "50%",
            maxHeight: "70%",
            overflowY: "scroll",
          }}
        >
          <CriarPasta close={() => setOpen(false)} />
          <br />
          <Divider style={{ marginTop: "0.1em", marginBottom: "0.5em" }} />
          <button className={classes.btnClose} onClick={() => setOpen(false)}>
            FECHAR
          </button>
        </Modal>

        {/* ---------> LISTAGEM DE ARQUIVOS <------------ */}

        

        {/* --------> UPLOAD DE ARQUIVOS <----------- */}

        

   
      </div>
    </div>
  );
}

export default RecursosHumanos;
