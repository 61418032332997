import * as types from './../types/pastas';

const INITIAL_STATE = {
  loading: false,
  pastas: [],
  page: Number(1),
  rows: 10,
  total: 0,
  categoria_disponivel: [],
  pastaFuncionario: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PASTAS:
      return { ...state, pastas: action.payload };

    case types.LOADING_PASTAS:
      return { ...state, loading: action.payload };

      case types.PASTAS_PAGE:
        return { ...state, page: action.payload };
        
      case types.PASTAS_PERPAGE:
        return { ...state, rows: action.payload };
        
      case types.PASTAS_TOTAL:
        return { ...state, total: action.payload };

      case types.SET_CATEGORIA_DISPONIVEL:
        return { ...state, categoria_disponivel : action.payload };


      case types.SET_PASTA_FUNCIONARIO:
        return { ...state, pastaFuncionario : action.payload };

    default:
      return state;
  }
};