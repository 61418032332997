import { Grid, InputAdornment } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { formatCNPJ, formatCPF } from "../../../Helpers";
import {
  DataGridTable,
  InputDate,
  InputSelect,
  InputText,
  TablePaginate,
} from "../../../components";
import { loadempresa } from "../../../store/actions/empresas";
import { loadFuncionarios } from "../../../store/actions/funcionarios";
import {
  inserirVinculos,
  loadFuncionariosDisponiveisVinculo,
  loadVinculos,
} from "../../../store/actions/vinculo_pessoa_empresa";
import * as types from "../../../store/types/vinculo_pessoa_empresa";
import useStyles from "../styles";

function ChooseConfig({
  close,
  funcionarios,
  showSuccessAlert,
  setShowSuccessAlert,
  showErrorAlert,
  setShowErrorAlert,
  messageError,
  setMessageError,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { empresas } = useSelector((state) => state.empresas);
  const {
    funcionariosDisponiveis,
    funcionariosPage,
    funcionarioDisponiveisTotal,
    funcionarioDisponiveisRows,
  } = useSelector((state) => state.vinculo_pessoa_empresa);
  const [situacao, setSituacao] = useState({
    status: "A",
    data_admissao: "",
    data_termino: "",
    matricula: "",
  });

  useEffect(() => {
    dispatch(loadempresa());
    dispatch(loadFuncionarios());
  }, []);

  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [buscaFuncionarioDebounce, setBuscaFuncionarioDebounce] = useState("");
  const [errorMessageUsuario, setErrorMessageUsuario] = useState(false);
  const [errorNomeNull, setErrorNomeNull] = useState(false);

  const handleInputChange = (e) => {
    const valor = e.target.value;
    setNomeFuncionario(valor);
  };

  useEffect(() => {
    const salvarNomeFuncionario = () => {
      setBuscaFuncionarioDebounce(nomeFuncionario);
    };
    const debounceTimeout = 800;
    let timeoutId;

    const handleChange = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        salvarNomeFuncionario();
      }, debounceTimeout);
    };

    handleChange();

    return () => clearTimeout(timeoutId);
  }, [nomeFuncionario]);

  console.log(funcionarioDisponiveisTotal);

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const initialStep = () => {
    setStep(step - 3);
  };

  const [empresa_select, setEmpresaSelect] = useState({});
  const [pessoa_select, setPessoaSelect] = useState({});
  const [inicio_contrato, setInicioContrato] = useState({ data: "01/01/2010" });

  const [empresaIdSelecionada, setEmpresaIdSelecionada] = useState("");

  function SelectedCompany(data) {
    nextStep();
    setEmpresaSelect(data);
    dispatch(
      loadFuncionariosDisponiveisVinculo(data.id, {
        page: Number(1),
        rows: funcionarioDisponiveisRows,
        filter: buscaFuncionarioDebounce,
      })
    );
    setEmpresaIdSelecionada(data.id);
  }

  useEffect(() => {
    {
      empresaIdSelecionada &&
        dispatch(
          loadFuncionariosDisponiveisVinculo(empresaIdSelecionada, {
            page: Number(1),
            rows: funcionarioDisponiveisRows,
            filter: buscaFuncionarioDebounce,
          })
        );
    }
  }, [buscaFuncionarioDebounce]);

  function SelectedPerson(data) {
    nextStep();
    setPessoaSelect(data);
  }

  async function ConfirmVinculo() {
    try {
      await inserirVinculos({
        empresa_id: empresa_select.id,
        funcionario_id: pessoa_select.id,
        inicio_contrato: situacao.data_admissao,
        fim_contrato: situacao.data_termino,
        status_operacional: situacao.status,
        matricula: situacao.matricula,
      });
      dispatch(loadVinculos());
      setMessageError("Vinculo gerado com sucesso!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      close();
      setSituacao("A");
      setEmpresaIdSelecionada("");
    } catch (error) {
      setMessageError("Erro ao gerar vínculo!");
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  const handleChangePage = async (event, newPage) => {
    await dispatch([
      {
        type: types.FUNCIONARIOS_DISPONIVEIS_PAGE,
        payload: parseInt(newPage) + Number(1),
      },
      loadFuncionariosDisponiveisVinculo(empresaIdSelecionada, {
        page: newPage + Number(1),
        rows: funcionarioDisponiveisRows,
      }),
    ]);
  };

  const handleChangeRowsPerPage = async (event) => {
    await dispatch([
      { type: types.FUNCIONARIOS_DISPONIVEIS_PAGE, payload: Number(1) },
      {
        type: types.FUNCIONARIOS_DISPONIVEIS_ROWS,
        payload: parseInt(event.target.value),
      },
      loadFuncionariosDisponiveisVinculo(empresaIdSelecionada, {
        page: Number(1),
        rows: parseInt(event.target.value),
      }),
    ]);
  };

  switch (step) {
    case 1:
      return (
        <div className={classes.root}>
          <Grid sm={12} style={{ marginTop: "2em" }}>
            <PerfectScrollBar>
              <DataGridTable
                columns={[
                  {
                    title: "Razao Social",
                    field: "razao_social",
                    cellStyle: { width: "50%" },
                  },
                  {
                    title: "CNPJ",
                    cellStyle: { width: "30%" },
                    render: ({ cnpj }) =>
                      cnpj &&
                      (cnpj.length === 11 ? formatCPF(cnpj) : formatCNPJ(cnpj)),
                  },

                  {
                    title: "Selecionar",
                    cellStyle: { textAlign: "center" },
                    render: (row) => (
                      <>
                        <button
                          onClick={() => SelectedCompany(row)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            color: "#3ca011",
                          }}
                        >
                          <CheckCircleOutline />
                        </button>{" "}
                      </>
                    ),
                  },
                ]}
                data={empresas}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
              />
              {/* <TablePaginate
            rowsPerPageOptions={[10, 30, 50, 100]}
            rowsPerPage={paginacao.perpage}
            count={paginacao.total}
            page={paginacao.page - 1}
            onChangePage={(event, newPage) => handleChangePage(newPage)}
            onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          /> */}
            </PerfectScrollBar>
          </Grid>

          <br />
        </div>
      );
    case 2:
      return (
        <div className={classes.root} style={{ fontSize: 12 }}>
          <br />
          Empresa Selecionada: <b>{empresa_select.razao_social}</b>
          <Grid sm={12} style={{ marginTop: "2em" }}>
            <Grid item sm={8} xs={8}>
              <InputText
                label="Digite o nome do funcionário para pesquisar"
                value={nomeFuncionario}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputChange}
              />
            </Grid>
            <PerfectScrollBar>
              <DataGridTable
                columns={[
                  {
                    title: "Funcionário",
                    field: "nome",
                    cellStyle: { width: "60%", fontSize: 12 },
                  },
                  {
                    title: "Documento",
                    field: "cpf_cnpj",
                    cellStyle: { width: "30%", fontSize: 12 },
                    render: ({ cpf_cnpj }) =>
                      cpf_cnpj &&
                      (cpf_cnpj.length === 11
                        ? formatCPF(cpf_cnpj)
                        : formatCNPJ(cpf_cnpj)),
                  },
                  {
                    title: "Selecionar",
                    cellStyle: { textAlign: "center", fontSize: 12 },
                    render: (row) => (
                      <>
                        <button
                          onClick={() => SelectedPerson(row)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            color: "#3ca011",
                          }}
                        >
                          <CheckCircleOutline />
                        </button>{" "}
                      </>
                    ),
                  },
                ]}
                data={funcionariosDisponiveis}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
              />
              <TablePaginate
                style={{ overflowX: "hidden" }}
                rowsPerPageOptions={[10, 20, 30]}
                rowsPerPage={+funcionarioDisponiveisRows}
                count={+funcionarioDisponiveisTotal}
                page={+funcionariosPage - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </PerfectScrollBar>
          </Grid>
          <br />
        </div>
      );
    case 3:
      return (
        <div className={classes.root}>
          <h5>Confirme as informações:</h5>
          <div>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                Empresa: {empresa_select.razao_social} <br />
              </Grid>

              <Grid item sm={12} xs={12}>
                Funcionário: {pessoa_select.nome}
              </Grid>
              <Grid item sm={4} xs={4}>
                <InputSelect
                  name="situacao"
                  label="Situacao do funcionário"
                  values={[
                    { value: "A", label: "Ativo" },
                    { value: "D", label: "Desligado" },
                  ]}
                  value={situacao.status}
                  onChange={(e) =>
                    setSituacao({ ...situacao, status: e.target.value })
                  }
                />
              </Grid>
              {situacao.status == "A" ? (
                <Grid item sm={4} xs={4}>
                  <InputDate
                    name="data_admissao"
                    fullWidth
                    label="Data de Admissão"
                    value={dayjs(situacao.data_admissao).format("YYYY-MM-DD")}
                    onChange={(d) =>
                      setSituacao({ ...situacao, data_admissao: d })
                    }
                  />
                </Grid>
              ) : (
                <>
                  <Grid item sm={4} xs={4}>
                    <InputDate
                      name="data_admissao"
                      fullWidth
                      label="Data de Admissão"
                      value={dayjs(situacao.data_admissao).format("YYYY-MM-DD")}
                      onChange={(d) =>
                        setSituacao({ ...situacao, data_admissao: d })
                      }
                    />
                  </Grid>
                  <Grid item sm={4} xs={4}>
                    <InputDate
                      name="data_termino"
                      fullWidth
                      label="Término do Contrato"
                      value={dayjs(situacao.data_termino).format("YYYY-MM-DD")}
                      onChange={(d) =>
                        setSituacao({ ...situacao, data_termino: d })
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item sm={4} xs={4}>
                <InputText
                  name="matricula"
                  fullWidth
                  label="Nº Matrícula"
                  value={situacao.matricula}
                  onChange={(e) =>
                    setSituacao({ ...situacao, matricula: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </div>
          <br />
          <div className={classes.btnConfirm}>
            <button onClick={() => ConfirmVinculo()}>CONFIRMAR VÍNCULO</button>
          </div>
          <br />
        </div>
      );
    default:
      return "";
  }
}

export default ChooseConfig;
