import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Logs:{
    width: "100%",
    height: "100vh",
  },
  LogsContainer:{
    boxShadow: "0px 0px 15px 0px #e3e3e3",
    borderRadius: '7px',
    backgroundColor: "#fff",
    width: "70%",
    margin: "auto",
    marginTop: "2em",
    '& h1':{
      fontSize: '18px',
      fontWeight: 'normal',
      padding: '7px 15px'
  }
  },
  btnEdit: {
    backgroundColor: '#060D13', 
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    padding: '5px 15px',
    cursor: 'pointer'
  }
}));

export default useStyles;