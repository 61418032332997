import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  makeStyles,
  Typography
} from "@material-ui/core";
import {
  Engineering,
  Input as InputIcon,
  SupervisorAccountOutlined as Supervisor
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FilePlus as Ativo, Home, Target, Trello } from "react-feather";
import { BiCategoryAlt, BiNetworkChart } from "react-icons/bi";
import { BsInboxes } from "react-icons/bs";
import { FaPeopleArrows } from "react-icons/fa";
import { GiInjustice } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { RiCoinsLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NavItem from "./NavItem";

const useStyles = makeStyles((theme) => ({
  mobileSidebar: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  desktopSidebar: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  logoutMobileButton: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  logoutButton: {
    marginTop: "auto",
    color: theme.palette.text.secondary,
    fontWeight: "medium",
    justifyContent: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
    width: "100%",
    height: "48px",
    "& svg": {
      marginRight: "8px",
    },
  },
  titleMenu: {
    fontSize: "14px",
    // marginLeft: '1em',
    borderTop: "1px solid #efefef",
    "& p": {
      marginLeft: "1.5em",
    },
  },
}));

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { usuario, loading } = useSelector((state) => state.usuario);

  const [permissions, setPermissions] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const _permissoes = localStorage.getItem("permissoes");
    const _usuario = localStorage.getItem("usuario");
    setUser(_usuario);
    setPermissions(_permissoes);
    // const _usuario = localStorage.getItem('usuario')
    // setUser(_usuario)
    // dispatch(
    //   { type: types.LOAD_USUARIOS_PERMISSAO, payload: JSON.parse(_permissoes)}
    //   )
  }, []);

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("usuario");
    localStorage.removeItem("empresa_id");
    localStorage.removeItem("permissoes");
    navigate("/");
  };




  const [subitemsVisible, setSubitemsVisible] = useState("");

  const [data, setData] = useState({
    home: [
      {
        href: "/app/home",
        title: "Home",
        icon: Home,
      },
    ],
    administrativo: [
      {
        href: "/app/administrativo",
        title: "Recursos Humanos",
        icon: Supervisor,
        subitems: [
          {
            href: "/app/administrativo/arquivo-ativo",
            title: "Arquivo Ativo",
            icon: Ativo,
          },
          {
            href: "/app/administrativo/arquivo-inativo",
            title: "Arquivo Inativo",
            icon: Target,
          },
        ],
      },
    ],
  });

  const home = { href: "/app/home", title: "Home", icon: Home };

  const recursos_humanos = {
    href: "/app/rh",
    title: "Documentos",
    icon: IoIosPeople,
  };

  const juridico = {
    href: "/app/juridico",
    title: "Jurídico",
    icon: GiInjustice,
  };

  const contabil = {
    href: "/app/contabil",
    title: "Contábil / Financeiro / Fiscal",
    icon: RiCoinsLine,
  };

  const engenharia = {
    href: "/app/engenharia",
    title: "Engenharia",
    icon: Engineering,
  };

  const cadastro_funcionario = {
    href: "/app/funcionarios",
    title: "Cadastro de Funcionários",
    icon: BiNetworkChart,
  };

  // const cadastro_usuario = {
  //   href: "/app/usuarios",
  //   title: "Cadastro de Usuários",
  //   icon: BiUserCircle,
  // };

  const perfil = {
    href: "/app/perfil",
    title: "Meu Perfil",
    icon: Engineering,
  };

  // const empresas = {
  //   href: "/app/empresa",
  //   title: "Cadastro de Empresas",
  //   icon: BiBuildings,
  // };

  const categorias = {
    // href: "/app/categoria-arquivos",
    title: "Cadastro de Arquivos",
    icon: BiCategoryAlt,
    subitems: [
      {
        href: "/app/categoria-arquivos",
        title: "Categorias de Arquivos",
      },
      {
        href: "/app/tipo-arquivos",
        title: "Tipo de Arquivos",
      },
      {
        href: "/app/enderecos",
        title: "Endereço de Arquivos",
      },
    ],
  };

  const vinculo_usuario_empresa = {
    href: "/app/vinculo",
    title: "Vínculo de Pessoa/Empresa",
    icon: FaPeopleArrows,
  };

  const grupos_de_arquivos = {
    href: "/app/grupos",
    title: "Grupos de Arquivos",
    icon: BsInboxes,
  };


  const TiposDeArquivosGeral = {
    href: "/app/tipo-arquivos-geral",
    title: "Tipos de Arquivos Geral",
    icon: BsInboxes,
  };

  const relatorios = {
    title: "Relatórios",
    icon: Trello,
    subitems: [
      {
        href: "/app/relatorios/logs",
        title: "Log de Atividade",
        icon: Trello,
      },
    ],
  };


  const CategoriaArquivos = {
    href: "/app/categoria-arquivos-geral",
    title: "Categorias Geral",
    icon: FaPeopleArrows,
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box display="flex" flexDirection="column" alignItems="center" py={1}>
        <Typography
          color="textPrimary"
          variant="h5"
          style={{ fontSize: "16px" }}
        >
          {user}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <NavItem
          href={home.href}
          title={home.title}
          icon={home.icon}
          // style={{ marginTop: "1em" }}
        />
        {permissions?.includes(7) && (
          <List>
            <NavItem
              href={cadastro_funcionario.href}
              title={cadastro_funcionario.title}
              icon={cadastro_funcionario.icon}
            />
          </List>
        )}

       
        {permissions?.includes(10) && (
          <List>
            <NavItem
              href={categorias.href}
              title={categorias.title}
              icon={categorias.icon}
              subitems={categorias.subitems}
            />
          </List>
        )}

        {permissions?.includes(11) && (
          <List>
            <NavItem
              href={vinculo_usuario_empresa.href}
              title={vinculo_usuario_empresa.title}
              icon={vinculo_usuario_empresa.icon}
            />
          </List>
        )}

        {permissions?.includes(2) && (
          <List>
            <NavItem
              subitems={recursos_humanos.subitems}
              href={recursos_humanos.href}
              title={recursos_humanos.title}
              icon={recursos_humanos.icon}
            />
          </List>
        )}


{/* <List>
            <NavItem
              href={CategoriaArquivos.href}
              title={CategoriaArquivos.title}
              icon={CategoriaArquivos.icon}
            />
          </List>
<List>
            <NavItem
              href={TiposDeArquivosGeral.href}
              title={TiposDeArquivosGeral.title}
              icon={TiposDeArquivosGeral.icon}
            />
          </List> */}

        {/* {permissions?.includes(17) && (
          <List>
            <NavItem
              subitems={grupos_de_arquivos.subitems}
              href={grupos_de_arquivos.href}
              title={grupos_de_arquivos.title}
              icon={grupos_de_arquivos.icon}
            />
          </List>
        )} */}

        <Box className={classes.logoutMobileButton}>
          <Button className={classes.logoutButton} onClick={signOut}>
            <InputIcon /> Sair
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box zIndex={1}>
      <Box className={classes.desktopSidebar}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            style: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box className={classes.mobileSidebar}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            style: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </Box>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
