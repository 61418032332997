import { Divider, Grid, Tooltip } from "@material-ui/core";
import { PeopleAltOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../components/AlertComponent";
import { DataGridTable } from "../../components/index";
import {
  loadCategorias,
} from "../../store/actions/categoria_arquivos";
import useStyles from "./styles.jsx";

function CategoriaArquivoNaoDigitalizado() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categorias } = useSelector((state) => state.categoria_arquivo);

  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);

  const [paginacao, setPaginacao] = useState({
    perpage: 10,
    total: 0,
    page: 0,
  });
  const [openDocsUpload, setOpenDocsUpload] = useState(false);
  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState({ descricao: "" });
  const [modal_insert, setModalInsert] = useState({ descricao: "" });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState("");

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };



  useEffect(() => {
    dispatch(loadCategorias());
  }, []);




  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Categoria de Arquivos geral:
        </h4>
        <Divider />
        <br />
     

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar>
            <DataGridTable
              columns={[
                {
                  title: "Data de criação",
                  cellStyle: { width: "20%", textAlign: "center" },
                  render: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
                },
                { title: "Descrição", field: "descricao" },
                {
                  title: "Status",
                  field: "status",
                  render: (row) => (row.status == "A" ? "Ativo" : "Inativo"),
                  cellStyle: { width: "15%", textAlign: "center" },
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%", textAlign: "center" },
                  render: (row) => {
                    return (
                      <>                  
                        <Tooltip title="Funcionários sem o arquivo">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() =>
                              navigate(`/app/categoria-arquivos-geral/${row.id}`)
                            }
                          >
                            <PeopleAltOutlined style={{ width: "20px" }} />{" "}
                          </button>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              data={categorias}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
              }}
            />
        
          </PerfectScrollBar>
        </Grid>

        {showSuccessAlert && (
          <AlertComponent
            type="success"
            message={messageError}
            timeout={3000}
          />
        )}

        {showErrorAlert && (
          <AlertComponent type="error" message={messageError} timeout={3000} />
        )}
    
      </div>
    </div>
  );
}

export default CategoriaArquivoNaoDigitalizado;
