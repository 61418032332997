import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginImage from "../../assets/images/login.png";
import icone from "../../assets/images/support_logo.png";
import { autenticarUsuario } from "../../store/actions/usuario";
import AlertComponent from '../../components/AlertComponent' 

import { Box, Formulario, Home } from "./styles";

function Login() {
  const [showPass, setShowPass] = useState(false);

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  const navigate = useNavigate();

  const [data, setData] = useState({ email: "", password: "" });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  async function sendLogin(event) {
    event.preventDefault();
    try {
      await autenticarUsuario(data.email, data.password);
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      navigate("/app/home", { replace: true });
    } catch (error) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  return (
    <Home>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={6}>
          <div style={{ textAlign: "center", padding: "1em" }}>
            <img src={loginImage} />
          </div>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Box>
            <div style={{ textAlign: "center", padding: "1em" }}>
              <img src={icone} style={{ width: "340px", marginTop: 40 }} />
            </div>
            <h3 style={{ textAlign: "center" }}>Área do Cliente</h3>

            <Formulario onSubmit={sendLogin}>
              <label>
                <div>
                  <TextField
                    type="email"
                    value={data.email}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    onChange={(event) =>
                      setData({
                        ...data,
                        email: event.target.value,
                      })
                    }
                    id="email"
                    label="E-mail"
                  />
                </div>
                <div>
                  <TextField
                    type={showPass ? "text" : "password"}
                    value={data.password}
                    onChange={(event) =>
                      setData({
                        ...data,
                        password: event.target.value,
                      })
                    }
                    id="password"
                    label="Senha"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "Pointer", opacity: "0.7" }}
                          onClick={ShowPassword}
                        >
                          <IconButton onClick={ShowPassword}>
                            {showPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </label>
              <br />
              <div>
                <Button
                  variant="outlined"
                  fullWidth
                  type="submit"
                  color="primary"
                >
                  Entrar
                </Button>
              </div>
              <br />
            </Formulario>
            <div style={{ paddingBottom: "2em", textAlign: "center" }}>
              <Link to="/esqueci">esqueci minha senha</Link>
            </div>

            <br />
          </Box>
        </Grid>
      </Grid>
      {showSuccessAlert && (
        <AlertComponent type="success" message="Login efetuado com sucesso!" timeout={3000} />
      )}

      {showErrorAlert && (
        <AlertComponent type="error" message="Usuário ou senha incorretos!" timeout={3000} />
      )}
    </Home>
  );
}

export default Login;
