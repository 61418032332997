import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/grupoDeArquivos";

export function loadGrupoDeArquivos() {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: true });
    http.get(`/api/v1/docs_group/list`).then(
      (response) => {
        const data = response.data?.data;

        store.dispatch([
          { type: types.SET_GRUPO_DE_ARQUIVOS, payload: data },
          { type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: false },
        ]);
        resolve(data)
      },
      (error) => {
        store.dispatch({ type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: false });
       reject(error);
      }
    );
  }) 
 
}

export function inserirGrupoDeArquivos(categoria) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: true });
    http.post(`/api/v1/docs_group/insert`, categoria).then(
      ({ data }) => {
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        resolve();
      },
      (e) => {
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        reject();
      }
    );
  });
}

export const loadGruposCadastradosUsuario = (id) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/api/v1/files_docs_group/list/${id}`)
      .then((response) => {
        const data = response.data?.data;
        store.dispatch({ type: types.SET_GRUPOS_CADASTRADOS, payload: data });
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export function CadastroGrupoDeArquivos(dados_cadastro) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: true });
    http.post(`/api/v1/files_docs_group/create`, dados_cadastro).then(
      ({ data }) => {
        store.dispatch({ type: types.SET_GRUPO_DE_ARQUIVOS, payload: data });
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        resolve();
      },
      (e) => {
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        reject();
      }
    );
  });
}


export function AtualizarGrupoDeArquivos(dados_cadastro, id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: true });
    http.put(`/api/v1/files_docs_group/update/${id}`, dados_cadastro).then(
      ({ data }) => {
        store.dispatch({ type: types.SET_GRUPO_DE_ARQUIVOS, payload: data });
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        resolve();
      },
      (e) => {
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        reject();
      }
    );
  });
}

export function EditarNomeGrupoArquivo(categoria, id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_GRUPO_DE_ARQUIVOS, payload: true });
    http.post(`/api/v1/docs_group/edit/${id}`, categoria).then(
      ({ data }) => {
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        resolve();
      },
      (e) => {
        store.dispatch({
          type: types.LOADING_GRUPO_DE_ARQUIVOS,
          payload: false,
        });
        reject();
      }
    );
  });
}
