import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputSelect, InputText, Modal } from "../../../components";
import { loadEnderecos } from "../../../store/actions/enderecos";
import { upload_arquivos } from "../../../store/actions/pastas";

function ModalDigitalizarArquivo({
  openModal,
  closeModal,
  pasta,
  tipo_de_arquivos,
  showSuccessAlert,
  setShowSuccessAlert,
  showErrorAlert,
  setShowErrorAlert,
  messageError,
  setMessageError,
}) {
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  const [listaDeArquivos, setListadeArquivos] = useState();
  const { enderecos } = useSelector((state) => state.enderecos);
  const [data_select, setDataSelect] = useState({ arquivo: "", endereco: "" });
  const [data_upload, setDataUpload] = useState({ nome: "" });
  const [uploadArq, setUploadArq] = useState(false);

  useEffect(() => {
    dispatch(loadEnderecos());
  }, []);

  async function uploadDeArquivo() {
    const file = uploadRef.current.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("size", file && file?.size ? 0 : file?.size);
    formData.append(
      "nome",
      data_upload && data_upload.nome === undefined
        ? "Arquivo"
        : data_upload.nome
    );
    formData.append("pasta_id", pasta.id);
    formData.append("endereco_id", data_select.endereco);
    formData.append("tipo_arquivo_id", data_select.arquivo);

    try {
      await upload_arquivos(formData);
      closeModal();
      setUploadArq(false);
      setDataSelect({ arquivo: "", endereco: "" });
      setMessageError("Arquivo enviado com sucesso!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
    } catch (error) {
      setMessageError("Erro ao enviar arquivo!");
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
      setUploadArq(false);
    }
  }

  function FecharModalVizualizacaodeArquivos() {
    closeModal();
    setListadeArquivos();
  }

  return (
    <Modal open={openModal}>
      <h6 style={{ marginBottom: 0 }}>
        Preencha as informações abaixo para upload do arquivo
      </h6>
      <br />
      <b>Pasta Selecionada - {pasta?.descricao}</b>
      <Divider style={{ marginBottom: "0.5em", marginTop: "0.5em" }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputSelect
            label="Tipo de Arquivo"
            value={data_select.arquivo}
            onChange={(e) =>
              setDataSelect({ ...data_select, arquivo: e.target.value })
            }
            values={[{ value: "", label: "Selecione" }].concat(
              tipo_de_arquivos &&
                tipo_de_arquivos.map((item) => ({
                  value: item.id,
                  label: item.descricao,
                }))
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputSelect
            label="Endereço Físico do Arquivo"
            value={data_select.endereco}
            onChange={(e) =>
              setDataSelect({ ...data_select, endereco: e.target.value })
            }
            values={[{ value: "", label: "Selecione" }].concat(
              enderecos &&
                enderecos.map((item) => ({
                  value: item.id,
                  label: item.descricao,
                }))
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputText
            value={data_upload.nome}
            label="Observação do Arquivo"
            onChange={(e) =>
              setDataUpload({ ...data_upload, nome: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <br />
      <input type="file" name="file" ref={uploadRef} accept="application/pdf" />

      <br />
      <Divider style={{ marginBottom: "0.5em", marginTop: "0.5em" }} />
      <div style={{ textAlign: "right" }}>
        {uploadArq ? (
          <CircularProgress
            size="18px"
            style={{ marginRight: "1em", color: "#2dce90" }}
          />
        ) : (
          <button
            style={{
              padding: "7px 25px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              cursor: "pointer",
              border: "1px solid #17a0c6",
              color: "#115d72",
              float: "right",
              marginTop: "10px",
              marginLeft: "15px",
            }}
            onClick={() => [uploadDeArquivo(), setUploadArq(true)]}
          >
            Inserir Arquivo
          </button>
        )}
        &nbsp;
        <button
          style={{
            padding: "7px 25px",
            backgroundColor: "transparent",
            borderRadius: "5px",
            cursor: "pointer",
            border: "1px solid #b51c1c",
            color: "#590909",
            float: "right",
            marginTop: "10px",
          }}
          onClick={() => FecharModalVizualizacaodeArquivos()}
        >
          Fechar
        </button>
      </div>
    </Modal>
  );
}

export default ModalDigitalizarArquivo;
