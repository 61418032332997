import { Divider, Grid, Tooltip } from "@material-ui/core";
import { PeopleAltOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DataGridTable,
  TablePaginate
} from "../../components/index";
import { loadCategorias } from "../../store/actions/categoria_arquivos";
import { loadTipodeArquivo } from "../../store/actions/tipos_de_arquivos";
import * as types from "../../store/types/tipos_de_arquivos";
import useStyles from "./styles.jsx";

function TiposDeArquivosGeral() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tipo_de_arquivos, rows, page, total } = useSelector(
    (state) => state.tipo_de_arquivos
  );
  const { categorias } = useSelector((state) => state.categoria_arquivo);

  const [paginacao, setPaginacao] = useState({
    perpage: 10,
    total: 0,
    page: 0,
  });
  const [openDocsUpload, setOpenDocsUpload] = useState(false);



  useEffect(() => {
    dispatch([
      loadTipodeArquivo({ page: Number(1), rows, status: "A" }),
      loadCategorias(),
    ]);
  }, []);

  const ListRender = (item) =>
    categorias &&
    categorias.find((lista) => lista.id == item?.categoria_id)?.descricao;

  const handleChangePage = async (event, newPage) => {
    dispatch([
      {
        type: types.LOAD_TIPO_DE_ARQUIVO_PAGE,
        payload: parseInt(newPage) + Number(1),
      },
      loadTipodeArquivo({ page: newPage + Number(1), rows }),
    ]);
  };

  const handleChangeRowsPerPage = async (event) => {
    dispatch([
      { type: types.LOAD_TIPO_DE_ARQUIVO_PAGE, payload: Number(1) },
      {
        type: types.LOAD_TIPO_DE_ARQUIVO_PERPAGE,
        payload: parseInt(event.target.value),
      },
      loadTipodeArquivo({
        page: Number(1),
        rows: parseInt(event.target.value),
      }),
    ]);
  };

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Tipos de Arquivo:
        </h4>
        <Divider />
        <br />

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar>
            <DataGridTable
              columns={[
                {
                  title: "Data de criação",
                  cellStyle: { width: "20%", textAlign: "center" },
                  render: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
                },
                { title: "Descrição", field: "descricao" },
                {
                  title: "Categoria Arquivo",
                  field: "categoria_id",
                  render: (row) => row.categoria_id && ListRender(row),
                },
                {
                  title: "Status",
                  field: "status",
                  render: (row) => (row.status == "A" ? "Ativo" : "Inativo"),
                  cellStyle: { width: "15%", textAlign: "center" },
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%", textAlign: "center" },
                  render: (row) => {
                    return (
                      <>
                        <Tooltip title="Funcionários sem o arquivo">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() =>
                              navigate(`/app/tipo-arquivos-geral/${row.id}`)
                            }
                          >
                            <PeopleAltOutlined style={{ width: "20px" }} />{" "}
                          </button>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              data={tipo_de_arquivos}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
              }}
            />
            <TablePaginate
              style={{ overflowX: "hidden" }}
              rowsPerPageOptions={[10, 20, 30]}
              rowsPerPage={+rows}
              count={+total}
              page={+page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PerfectScrollBar>
        </Grid>
      </div>
    </div>
  );
}

export default TiposDeArquivosGeral;
